@import "../../../assets/sass/colors.scss";

.title {
  font-family: "ducati";
  font-size: 9px;
  line-height: 24px;
  text-align: left;
  color: $smoky-black;
}

.label {
  font-family: "ducati-bold";
  font-size: 9px;
  line-height: 12px;
  text-align: left;
  color: $dark-gray;
}

.labeldesc {
  font-family: "ducati";
  font-size: 9px;
  line-height: 12px;
  text-align: left;
  color: $dark-gray;
}

.desc {
  font-family: "ducati";
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.0025em;
  text-align: left;
  color: $black;
}

.border {
  border-bottom: 1px solid $smoky-black;
  opacity: 0.1;
  border-radius: 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.icon {
  width: auto;
  min-width: 36px;
  max-width: 72px;
  margin-right: 20px;
}
