@import "../../../common/assets/sass/colors.scss";

.profile-container {
  text-transform: none !important;
}

.img-profile {
  width: 23.33px;
  height: 23.33px;
  border-radius: 20px;
}

.profile-name {
  margin: 0;
  padding-top: 3px;
}

.profile-mobile h6 {
  color: $ducati-red;
  margin-top: calc((36.67px / 2) / 2);
  margin-top: -moz-calc((36.67px / 2) / 2);
  margin-top: -webkit-calc((36.67px / 2) / 2);
}

.profile-mobile {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 00.007em;
  text-transform: uppercase;
  margin-top: 33px;
  margin-left: 28px;
  margin-bottom: 7px;
}

.img-profile-mobile {
  width: 36.67px;
  height: 36.67px;
  border-radius: 20px;
  border: 2px solid $ducati-red;
  margin-right: 13px;
}

.profile-name-mobile {
  padding: 0;
}

.text_truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font2 {
  font-family: "ducati-bold" !important;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.display {
  display: contents;
}
