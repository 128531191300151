@import "../../../../assets/sass/colors.scss";
@import "../../../../assets/sass/sizing.scss";

.selector-bar-container {
  width: 100%;
  position: absolute;
  bottom: 45px;
  margin: 0;
}

.selector-bar-content {
  display: flex !important;
  width: fit-content;
  background-color: rgba($color: white, $alpha: 0.75);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 21px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (max-width: $width-medium-max) and (orientation: portrait) {
  .selector-bar-container {
    display: none !important;
  }
}

@media (max-height: 650px) {
  .selector-bar-container {
    width: 100%;
    position: absolute;
    bottom: 5px;
    margin: 0;
  }
}

.selector-bar-content img {
  max-width: 100%;
  height: auto;
  padding: 0.5rem;
}

.max-width-arrow {
  max-width: 85% !important;
}

.loading_bar {
  min-width: 100px;
  height: 5px;
  border-radius: 4px;
  background-color: $feature-grey;
  position: relative;
  display: flex;
  width: 75%;
}

.loading_bar_progress {
  width: 0%;
  height: 100%;
  border-radius: 4px;
  background-color: $ducati-red;
  animation: loading-animation 0.7s infinite;
}
.selector-visibility-icon {
  width: 36px;
  height: 36px;
}

@keyframes loading-animation {
  0% {
    width: 0%;
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  }
  25% {
    clip-path: polygon(0 0, 0 100%, 20px 100%, 20px 0);
  }
  50% {
    width: 100%;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
  100% {
    width: 0%;
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 0);
  }
}

.selector_transparency {
  background-color: rgba($color: $white, $alpha: 0.85);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.arrow-left {
  left: 10px;
  top: 17%;
  padding-bottom: 50px;
}
.arrow-left {
  padding-top: 50px;
}
.arrow-right {
  right: 10px;
  top: 17%;
  padding-bottom: 50px;
}
.arrow-right {
  padding-top: 50px;
}

.circular_button {
  background-color: white !important;
  opacity: 0.75;
  border-radius: 100% !important;
}
