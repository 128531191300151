@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";
@import "../../../../../../assets/sass/button.scss";

.apparel-details-btn-remove {
  height: 48px;
  width: 100%;
}

.remove-btn {
  width: 220px !important;
  height: 56px !important;
  padding: 0 !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.progressbar-width {
  width: 50%;
}

@media (max-width: $width-medium-max) {
  .remove-btn {
    height: 48px !important;
    width: calc(100% - 40px) !important;
    margin: 48px 20px 0 20px !important;
    letter-spacing: 0.005em !important;
  }
  .progressbar-width {
    width: 100%;
    margin-top: 1rem;
  }
}
