@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";

.apparel-color-title {
  font-family: "ducati";
}
.apparel-color-title-bold {
  font-family: "ducati-bold-extra";
}
.apparel-color-title,
.apparel-color-title-bold {
  font-size: 18px;
  line-height: 27px;
  color: $smoky-black;
}

.apparel-color-btn {
  border-radius: 2px !important;
  padding: 0 !important;
}

.apparel-color-list-ul {
  display: flex !important;
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.apparel-color-list-li-active {
  border: 1px solid #000000 !important;
  border-radius: 2px !important;
}

.apparel-color-img {
  height: 42px !important;
  width: 42px !important;
}

.apparel-color-list-li {
  width: auto !important;
  height: auto !important;
  padding: 7px !important;
  margin-right: 21px !important;
}

@media (min-width: $width-large-min) {
  .apparel-color-title-bold {
    font-family: "ducati-bold";
  }
}
