@import "../../assets/sass/button.scss";
@import "../../assets/sass/sizing.scss";

.overflow-x {
  overflow-x: hidden;
}

.cs-content {
  overflow-y: auto;
}

.cs-content::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.cs-content::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.font-title,
.section-title,
.card-title,
.card-btn-label,
.card-price,
.sub-total-font,
.sub-tota-font-desc,
.sub-total-price,
.total-font,
.total-subfont,
.total-price,
.card-subtitle {
  color: $smoky-black;
}

.font-title {
  font-family: "ducati-extended-bold";
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.004em;
  text-align: left;
}

.section-title {
  font-family: "ducati-extended";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.card-container {
  background: $white;
}

.card-content {
  border-bottom: 1px solid $light-gray;
}

.card-title {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.007em;
}

.card-price {
  font-family: "ducati-extended-bold";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: end;
}

.cs-listing {
  border-bottom: 1px solid $feature-grey;
}

.sub-total-font {
  font-family: "ducati-bold";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  text-align: left;
}

.sub-tota-font-desc {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.sub-total-price {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
  text-align: right;
}

.total-font {
  font-family: "ducati-bold";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  text-align: left;
}

.total-subfont {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  hyphens: auto;
  text-align: left;
}

.total-price {
  font-family: "ducati-extended-bold";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.006em;
  text-align: right;
}

.button-style {
  min-height: 48px;
  padding: 12px;
  text-decoration: none;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.card-subtitle {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  text-align: left;
}

.quantity-selector {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}

.form-card-text {
  font-family: "ducati";
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.form-card-text:first-letter {
  text-transform: uppercase;
}

@media (min-width: $width-large-min) {
  .contentCartHeight_b2b {
    max-height: calc(100vh - 76px - 19px - 192px - 24px - 112px - 83px);
  }
  .contentCartHeight {
    max-height: calc(100vh - 76px + 5px - 112px - 83px);
  }
  .card-content {
    border: none;
  }
  .card-btn-label {
    text-align: end;
  }
  .sub-total-font {
    font-family: "ducati";
  }
  .sub-total-font,
  .sub-total-price {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.006em;
  }
  .total-font {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.006em;
  }
  .total-subfont {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.008em;
  }
  .total-price {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.005em;
  }
  .button-style {
    width: 100%;
    max-width: 179px !important;
  }
  .card-subtitle {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.007em;
  }
  .form-card-text {
    font-size: 16px !important;
    line-height: 26px;
    letter-spacing: 0.007em;
    width: auto !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

@media (max-width: $width-medium-max) {
  .quantitySpace {
    justify-content: space-between;
  }
  .quantitySpace div {
    margin-right: 16px !important;
  }
}
