@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";
@import "../../assets/sass/button.scss";

.button-style {
  width: 100%;
  min-height: 48px;
  padding: 12px !important;
  @media screen and (min-width: $width-large-min) {
    max-width: 300px !important;
  }
}
