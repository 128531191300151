@import "../../colors.scss";

$font-family-sans-serif: "ducati", sans-serif;
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1025px,
  xl: 1201px,
  xxl: 1400px,
) !default;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
);

$light: $light-gray;
$dark: $smoky-black;

$accordion-bg: transparent;
$accordion-button-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: black;
$accordion-button-focus-border-color: transparent;
$accordion-icon-width: 0.75rem;
$accordion-icon-transition: transform 0.3s ease-in-out;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg width='12' height='09' viewBox='0 0 12 09' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.333984 2.3335L2.33398 0.333496L6.33398 1.66683L10.334 0.333496L12.334 2.3335L6.33398 9.66683L0.333984 2.3335Z' fill='%230F0F0F'/%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg width='12' height='09' viewBox='0 0 12 09' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.333984 2.3335L2.33398 0.333496L6.33398 1.66683L10.334 0.333496L12.334 2.3335L6.33398 9.66683L0.333984 2.3335Z' fill='%230F0F0F'/%3E%3C/svg%3E");

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$h7-font-size: 0.875rem;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
);

$accordion-border-color: transparent;

$secondary: $dark-gray;
$danger: $ducati-red;
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
