@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

/*! purgecss start ignore */

#rate-modal-container {
  #calculator-interface-container,
  #fswebCALCInterfaceContainer,
  .panel-collapse {
    display: block;
  }

  /* .level1 .form-group:last-of-type, */
  .level1 .form-group:first-of-type,
  .help-group {
    display: none !important;
  }

  .form-group,
  .label-group {
    display: flex;
  }

  .form-group,
  .panel-heading,
  .disclaimers {
    border-top: 1px solid $feature-grey;
  }

  .panel-toggle,
  .control-label,
  .header-rate-style {
    font-family: "ducati-extended-bold";
    color: $black;
  }

  .input-group-addon,
  .disclaimers {
    font-family: "ducati";
  }

  .input-group-addon,
  .header-rate-style-span {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.006em;
  }

  .form-control,
  .selectpicker {
    height: 62px;
    border-color: $dark-gray !important;
    border-radius: 4px !important;
  }

  .header-rate-style-border {
    border-right: 1px solid $black;
  }

  .form-group {
    padding: 27px 0 33px 0;
    position: relative;
    align-items: center;
  }

  .form-group:nth-child(2),
  .form-group:nth-child(3),
  .form-group:nth-child(4) {
    padding: 16px 0;
  }

  .panel-heading {
    padding: 40px 0;
    text-align: center;
  }

  .panel-toggle {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.004em;
    text-decoration: none;
  }

  .selectpicker {
    max-width: 112px !important;
  }

  .control-label {
    font-size: 24px;
    letter-spacing: 0.005em;
  }

  .input-group {
    width: 66.66666667% !important;
    justify-content: end;
    align-items: center !important;
  }

  .form-control {
    width: auto !important;
    max-width: 172px;
  }

  .label-group {
    flex: 100%;
    justify-content: right !important;
  }

  .label-group .form-control {
    padding: 0;
    padding-left: 12px;
    display: inline;
    border: none;
    text-align: end;
    order: 2;
  }

  .form-group:nth-of-type(3) .input-group .form-control {
    order: 2;
    margin-left: 12px;
  }

  .label-group span {
    font-family: "ducati-bold";
    font-size: 18px;
    color: $black;
    line-height: 28px;
    height: auto !important;
  }

  .header-rate-style {
    font-size: 48px !important;
    line-height: 56px;
    letter-spacing: 0.0025em;
    margin-top: 56px;
  }

  .header-rate-style-span {
    font-family: "ducati";
  }

  .disclaimers {
    padding-top: 40px;
    color: $dark-gray;
    line-height: 26px;
    letter-spacing: 0.007em;
  }

  .disclaimers ul {
    padding-left: 0;
    list-style-type: none;
  }

  .level1 div:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .form-group {
    order: 2;
  }

  .form-group .control-label {
    display: flex !important;
    width: 50% !important;
  }
  .form-group .label-group {
    width: 50% !important;
  }
  .form-group .input-group {
    width: 50% !important;
  }

  //### START row with summary of monthly rate
  .form-group:last-of-type {
    display: flex;
    order: 1;
    border-top: none;
  }
  // label
  .form-group:last-of-type .control-label {
    display: flex !important;
    justify-content: center !important;
    border-right: 1px solid $black;
    font-size: 34px !important;
    width: 50% !important;
    flex: auto !important;
  }
  // amount monthly rate
  .form-group:last-of-type .label-group {
    padding-left: 2rem;
    display: flex;
    justify-content: center !important;
    width: 50% !important;
    flex: auto !important;
  }
  .form-group:last-of-type .label-group span {
    font-size: 34px !important;
  }
  .form-group:last-of-type .control-label .help-group {
    display: none !important;
  }
  .form-group:last-of-type .help-group .icon-help {
    display: none !important;
    content: none;
  }
  //### END row with summary of monthly rate

  //### START row with Duration
  .form-group:nth-child(2) .input-group {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .form-group:nth-child(2) .input-group .input-group-addon {
    padding-right: 1rem;
  }
  .form-group:nth-child(2) .help-group .icon-help {
    display: none !important;
    content: none;
  }
  .form-group:nth-child(3) .help-group .icon-help {
    display: none !important;
    content: none;
  }
  .form-group:nth-child(4) .help-group .icon-help {
    display: none !important;
    content: none;
  }
  //### END row with Duration

  //### START Tooltip
  .control-label .help-group {
    display: block !important;
    position: relative;
    width: auto !important;
    padding-left: 1rem;
  }
  .help-group span {
    display: none;
    width: 200px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $smoky-black !important;
    padding: 5px 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 9999 !important;
    top: 10%;
    left: 105%;
  }
  .help-group a {
    font-family: "ducati-condensed" !important;
    font-size: 16px;
    hyphens: auto;
    color: $smoky-black !important;
    text-decoration: none;
    pointer-events: none;
  }
  .help-group .icon-help {
    display: block !important;
    content: url("../../assets/images/black-info-icon.svg");
    margin-right: 0.5rem;
  }
  .control-label .help-group:hover span {
    display: block !important;
  }
  //### END Tooltip

  @media (max-width: $width-medium-max) {
    .panel-heading {
      padding-top: 20px;
    }

    .panel-toggle {
      font-size: 18px;
    }

    .header-rate-style {
      font-size: 24px !important;
      line-height: 20px;
    }

    .header-rate-style-span {
      display: block;
      font-size: 14px;
      justify-content: space-between;
    }

    .form-control {
      max-width: 112px;
    }

    .form-group {
      padding: 42px 0;
      justify-content: space-between;
    }

    .form-group:nth-child(2),
    .form-group:nth-child(3),
    .form-group:nth-child(4) {
      padding: 24px 0;
    }

    .control-label {
      font-family: "ducati";
      font-size: 16px;
    }
    .form-group:last-of-type .control-label {
      font-size: 20px !important;
      padding-right: 2rem;
    }
    .form-group:last-of-type .label-group {
      padding-left: 2rem;
    }
    .form-group:last-of-type .label-group span {
      font-size: 20px !important;
    }
    .control-label .help-group:active span {
      display: block !important;
    }
    .help-group span {
      top: 100%;
      left: 50%;
    }
  }

  @media (min-width: $width-large-min) {
    .control-label .help-group:hover span {
      display: block !important;
    }
  }
}

/*! purgecss end ignore */
