@import "../../assets/sass/colors.scss";

.pop-up {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 896px;
  border: 1px solid $white !important;
  border-radius: 4px !important;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #011627;
  padding-top: 32px;
  padding-bottom: 64px;
  height: 90vh;
  max-height: 710px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  animation-name: appear-animation;
  animation-duration: 0.2s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
}

.pop-up::-webkit-scrollbar {
  display: none;
}

.pop-up-text {
  padding: 0 78px;
}

.pop-up-background {
  width: 100vw;
  height: 1000vh;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  animation-name: appear-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
}

.close-button-label {
  font-family: "ducati";
  font-size: 14px;
}

@media (max-width: 992px) {
  .pop-up {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    padding-top: 24px;
    padding-bottom: 56px;
    background-color: $off-white;
  }

  .pop-up-text {
    padding: 0 24px;
  }
}

@keyframes appear-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
