$min-height-90: 90vh;

// Mobile Width
$width-xsmall-min: 320px;
$width-xsmall-medium: 350px;
$width-xsmall-max: 480px;
// Tablet Width
$width-medium-min: $width-xsmall-max + 1;
$width-medium-max: 1024px;
// Desktop Width
$width-large-min: $width-medium-max + 1;
$width-large-max: 1200px;
$width-extralarge-min: $width-large-max + 1;

$width-720p-min: 1279px;
$width-720p: $width-720p-min + 1;
$width-720p-max: $width-720p + 1;
