@import "../../../assets/sass/button.scss";
@import "../../../assets/sass/sizing.scss";

.save-myducati-modal-container {
  padding-left: 83px !important;
  padding-right: 83px !important;
  padding-bottom: 46px !important;
}

.save-myducati-modal-title {
  font-size: 18px;
  line-height: 28px;
  color: $black;
}

.save-myducati-modal-desc {
  font-family: var(--font-regular);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $black;
}

.save-myducati-modal-btn-container {
  flex-direction: column-reverse;
}

.save-myducati-modal-btn-left {
  padding-top: 0.5rem !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
.save-myducati-modal-btn-right {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0 !important;
}

.save-myducati-modal-btn-container a,
.save-myducati-modal-btn-container button {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.save-myducati-modal-btn-container a {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.save-myducati-modal-title {
  font-family: var(--font-extended-bold);
  letter-spacing: 0.007em;
}

.save-myducati-modal-email-descrption {
  font-family: var(--font-regular);
  font-size: 14px;
}

.save-myducati-modal-input {
  width: 100%;
  max-width: 452px;
  height: 58px;
  border: 2px solid $feature-grey;
  border-radius: 2px;
  outline: none;
}

.save-myducati-modal-email-btn {
  width: 100%;
  height: 60px;
  transition: 0.2s;
}

.btn-red {
  transition: none;
}

.save-myducati-modal-email-link {
  font-family: var(--font-regular);
  font-size: 14px;
  color: $smoky-black;
}

.email-progress-bar {
  width: 100%;
}

.btn_saved {
  height: 56px;
}

.send-mail-container {
  flex-direction: column;
}

@media (min-width: $width-large-min) {
  .send-mail-container {
    flex-direction: row;
  }
  .save-myducati-modal-btn-container {
    flex-direction: row;
  }
  .save-myducati-modal-btn-left {
    padding-top: 0 !important;
    padding-right: 0.5rem !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }
  .save-myducati-modal-btn-right {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0.5rem !important;
  }

  .save-myducati-modal-email-btn {
    width: 200px;
  }

  .email-progress-bar {
    width: 200px;
  }
  .btn_saved {
    width: 290px !important;
  }
}
