@import "../../assets/sass/sizing.scss";
@import "../../assets/sass/button.scss";

.content {
  width: 100%;
}

.mandatory {
  z-index: 2000 !important;
}

.btn-close-modal {
  display: contents;
  padding-top: 0.6rem !important;
  padding-left: 1rem !important;
}

.btn-close-modal img:hover {
  opacity: 0.6;
}

.modal-full-screen {
  max-width: 100% !important;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 100vh;
}

.border {
  border: none !important;
  border-radius: initial !important;
}

.font-close-text {
  font-family: var(--font-bold), Arial;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  margin-right: 16px;
}

.font-close-text-bundle {
  font-family: "ducati-bold", Arial;
  font-size: 14px;
  margin-left: 16px;
}

.max-height {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  border-radius: 0.5rem;
}

.max-height-conflict {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  border-radius: 0.5rem;
  width: 906px !important;
}

.max-height::-webkit-scrollbar {
  display: none !important;
}

.modal-content-apparel,
.modal-remove-apparel {
  background-color: $off-white !important;
  border-radius: 4px !important;
  width: auto !important;
}

.background {
  background-color: rgb(239 239 239 / 90%) !important;
}

.close-area {
  border-bottom: 0 !important;
  justify-content: end !important;
}

@media (max-width: $width-medium-max) {
  .modal-remove-apparel {
    width: 100% !important;
  }
  .close-area {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin-top: 16px !important;
  }
  .modalObject {
    width: 742px !important;
    height: 391px;
  }
}
.modalObject_title {
  font-family: "ducati-condensed-bold" !important;
  font-size: 20px;
}
.modalObject_subtitle {
  font-family: "ducati" !important;
  font-size: 14px;
}

.modalObject_subtitle_conflict {
  font-family: "ducati" !important;
  font-size: 14px;
  text-align: center;
}

@media (min-width: $width-large-min) {
  .content {
    width: 906px !important;
  }
}

.icon-size{
  width: 32px;
  height: 32px;
}
