@import "../../../assets/sass/colors.scss";
@import "../../../assets/sass/sizing.scss";

.carousel-configurator-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // background: $off-white;
  height: inherit;
}

.carousel-configurator-container,
.carousel-configurator-content,
.carousel-configurator-menu,
.carousel-configurator-img,
.carousel-configurator-footer {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.carousel-configurator-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-configurator-menu {
  display: flex;
  align-items: center;
}

.carousel-configurator-footer {
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
}

.carousel-configurator-footer-b2b {
  display: flex;
  position: inherit;
}

.carousel-configurator-mtsmhp-footer-b2b,
.carousel-configurator-mtsmhp-footer {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
}

.carousel-configurator-mtsmhp-footer-b2b {
  bottom: 30px;
}

.carousel-configurator-mtsmhp-footer-b2b-mto {
  width: 100%;
  display: flex;
  bottom: 30px;
}

.carousel-configurator-mtsmhp-footer {
  bottom: 75px;
}

.carousel-configurator-mto-footer {
  bottom: 10px !important;
}

.cursor {
  cursor: url("../../../assets/images/zoom/cursorZoom.svg"), pointer;
}

.size {
  width: 100% !important;
  height: 100% !important;
}
.size_rotation {
  transform: rotate(0deg);
}

.button-close {
  position: absolute;
  z-index: 2;
  max-width: calc(100% - 20px);
}

.key-panel {
  position: absolute;
  z-index: 2;
  max-width: calc(100% - 20px);
}

.view-menu {
  background-color: $off-white !important;
  width: auto !important;
  border-right: none !important;
}

.d-content {
  display: contents;
}

.margin-top-img {
  cursor: pointer;
}

.border-img {
  border: 1.5px solid #000000;
  border-radius: 5px;
}

.gallery-mode {
  background: rgba(255, 255, 255, 0.9);
}

@media (min-width: $width-large-min) {
  .carousel-configurator-footer {
    flex: 1;
    transform: translateX(-50%);
  }
  .carousel-configurator-content {
    flex: 25;
  }
}

@media (max-width: $width-medium-max) {
  .positionRotation {
    right: 30px;
    bottom: 10px;
  }
  .arrow-left,
  .arrow-left-mts {
    left: 10px;
    top: 17%;
    padding-bottom: 50px;
  }
  .arrow-left {
    padding-top: 50px;
  }
  .arrow-right,
  .arrow-right-mts {
    right: 10px;
    top: 17%;
    padding-bottom: 50px;
  }
  .arrow-right {
    padding-top: 50px;
  }
  .arrowCursor {
    position: fixed;
    margin-top: 76px;
    z-index: 1;
    top: 0;
    height: 55px;
  }
  .arrowWebViewMode {
    position: fixed;
    z-index: 1;
    height: 55px;
    top: 0;
  }

  .fixedHeight {
    height: 220px;
  }
}

.color-modal-rotate {
  background-color: $off-white;
}

.rotate {
  rotate: 90deg !important;
}

.img-zoom-size {
  height: 900px;
  width: 1350px;
}

.arrowCursor {
  cursor: pointer;
}

.zoom-close {
  padding-top: 22px;
  padding-right: 23px;
}

.zoom-arrow-up {
  bottom: 0;
  margin-bottom: 96px;
  margin-right: 72px;
}

.zoom-arrow-right {
  bottom: 0;
  margin-bottom: 57px;
  margin-right: 32px;
}

.zoom-arrow-down {
  bottom: 0;
  margin-bottom: 16px;
  margin-right: 72px;
}

.zoom-arrow-left {
  bottom: 0;
  margin-bottom: 57px;
  margin-right: 112px;
}

.progressbar_container {
  background-color: $off-white;
}

@media (min-height: 900px) {
  .img-zoom-size {
    height: 1200px;
    width: 1750px;
  }
}

@media (min-height: 1200px) {
  .img-zoom-size {
    height: 1500px;
    width: 2050px;
  }
}

@media (min-width: 2500px) {
  .carousel-configurator-menu {
    flex: 0.5;
  }
}

.circular_button {
  background-color: white !important;
  opacity: 0.75;
  border-radius: 100% !important;
}
