@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.specsLabel {
  font-family: var(--font-bold);
  font-size: 16px;
  color: $dark-gray;
}

.specsLabelDesc {
  font-family: var(--font-regular);
  font-size: 16px;
  color: $dark-gray;
}

.specsTitle {
  font-family: var(--font-regular);
  font-size: 16px;
  color: $smoky-black;
  margin-bottom: 28px;
}
.specsBorder {
  border-bottom: 1px solid $smoky-black;
  opacity: 0.1;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (max-width: $width-medium-max) {
  .specsLabelDesc,
  .specsLabel {
    color: $smoky-black;
  }
}
