@import "../../assets/sass/button.scss";
@import "../../assets/sass/sizing.scss";

.left-side-container {
  background-color: $white;
  width: 100%;
  align-self: center;
  text-align: -webkit-center;
  padding: 0 !important;
}

.buttonSidebar {
  background: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: none;
  border-right: none;
  position: relative;
}

.buttonSidebar img {
  width: 35px;
  height: 45px;
}

.buttonSidebar:hover {
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
}

.menuCol {
  position: absolute;
  align-items: center;
  height: 100%;
  z-index: 1;
}

.iconMenu {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px;
  border-radius: 5px;
  // backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(5px);
}

.iconMenu img {
  cursor: pointer;
}

.dn_icon {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.icon_not_visible {
  position: absolute;
  top: 0;
  left: 5px;
}

.icon_visible {
  opacity: 1;
}

.transparent-title {
  font-family: var(--font-bold-extra);
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.004em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.transparent-flag {
  font-family: var(--font-regular);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.008em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.custom-control-input,
.custom-control-input:checked,
.custom-control-label::before,
.custom-control-input:active,
.custom-control-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e") !important;
}

@media (min-width: $width-large-min) {
  .left-side-container {
    height: calc(100vh - 76px);
  }
  .buttonSidebar img {
    width: 40px;
    height: 48px;
  }
  .iconMenu {
    border-radius: 0 5px 5px 0;
  }
  .transparent-title {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.007em;
  }
  .transparent-flag {
    font-family: var(--font-bold);
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.007em;
  }
}

@media (max-width: $width-medium-max) {
  .iconMenu {
    position: absolute;
    margin-left: 38px;
    z-index: 1;
  }

  .left-side-rotation-icon {
    bottom: 4%;
    right: 4%;
  }
}
