@import "../../../../assets/sass/colors.scss";
@import "../../../../assets/sass/sizing.scss";

.entertainment-menu-container {
  margin: 0;
  padding: 0;
  text-align: initial !important;
}

.entertainment-menu-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(15px);
  border-radius: 0px 5px 5px 0px;
  transition: 0.5s ease-in-out;
}

@media (min-width: 1550px) {
  .entertainment-menu-buttons {
    max-width: 105px;
  }
}

.menu-button {
  padding: 12.5px !important;
  border-top: 2px solid transparent !important;
  border-left: 0 !important;
  border-bottom: 2px solid transparent !important;
  border-right: 0 !important;
  border-radius: 0 !important;
}

.menu-button-active {
  border-top: 2px solid $black !important;
  border-bottom: 2px solid $black !important;
}

.view-menu {
  background-color: $off-white !important;
  transform: translateX(110%) !important;
}

.border-selected {
  border-top: 2px solid #000000 !important;
  border-bottom: 2px solid #000000 !important;
}

.border-selected-menu {
  border-top: none !important;
  border-bottom: none !important;
}

.border-selected-menu:hover {
  border-top: 2px solid #000000 !important;
  border-bottom: 2px solid #000000 !important;
}

@media (max-width: $width-medium-max) {
  .entertainment-menu-container {
    z-index: 2000;
    margin-left: 38px;
    position: absolute;
  }
  .entertainment-menu-buttons {
    border-radius: 5px;
  }
}

@media (min-width: $width-large-min) {
  .menu-button {
    margin-top: 15px !important;
    margin-left: 14px !important;
    margin-bottom: 15px !important;
    margin-right: 14px !important;
  }
}
