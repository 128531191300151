@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.btn-close-modal {
  display: contents;
}

.font-title1-modal {
  font-family: var(--font-extended-bold), Arial;
  font-size: 18px !important;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: $black;
}

.font-title1-modal::first-letter {
  text-transform: capitalize;
}

.font-descr1-modal {
  font-family: var(--font-regular), Arial;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $black;
}

@media (min-width: $width-large-min) {
  .price-info-modal-body {
    width: 896px !important;
    height: 627px;
  }
}
