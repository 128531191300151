@import "../../../../../common/assets/sass/colors.scss";
@import "../../../../../common/assets/sass/sizing.scss";
@media (max-width: 991px) {
  .font-title {
    font-size: 32px !important;
  }

  .font-subtitle {
    font-size: 20px !important;
  }
}
.title {
  max-width: 90% !important;
}

.font-title {
  font-family: "ducati-extended-bold", Arial;
  font-size: 32px;
}

.font-title--scrambler {
  font-family: "scrambler-ex-extra-bold", Arial;
  font-size: 32px;
}

.font-subtitle {
  font-family: "ducati-extended-bold", Arial;
  font-size: 24px;
}

.font-subtitle--scrambler {
  font-family: "scrambler-ex-extra-bold", Arial;
  font-size: 24px;
}

.font-subdescr {
  font-family: "ducati", Arial;
  font-size: 16px !important;
}

.content {
  padding-top: 26px;
}

.color_scrollbar,
.color_scrollbar--scrambler {
  max-height: calc(100vh - 76px - 83px - 26px - 19px);
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
}

.color_scrollbar::-webkit-scrollbar,
.color_scrollbar--scrambler::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.color_scrollbar::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.color_scrollbar--scrambler::-webkit-scrollbar-thumb {
  background-color: $black;
  border-radius: 4px;
}
@media (max-width: $width-medium-max) {
  .content {
    background-color: $off-white;
    position: relative;
    z-index: 0;
  }
  .stickybar {
    margin-top: 191px;
    padding-bottom: 83px;
  }
  .stickybarViewMode {
    margin-top: 115px;
    margin-bottom: 83px;
  }
  .color_scrollbar {
    max-height: none;
    overflow-y: none;
  }
}
