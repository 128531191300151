@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";

.leftbar-container {
  display: none;
  background: $bg-color;
  flex-direction: column;
  justify-content: left;
  padding-left: 1.3rem !important;
  padding-right: 1.3rem !important;
}

.leftbar-row {
  border-bottom: 1px solid $feature-grey !important;
}

.leftbar-close-area {
  align-items: center;
  margin-top: 25px !important;
  margin-bottom: 42px !important;
}

.leftbar-row:last-child {
  border: none !important;
}

.leftbar-title-label {
  font-family: var(--font-bold);
  padding-left: 0 !important;
  padding-top: 0.9rem !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.leftbar-desc-label {
  font-family: var(--font-regular);
  margin: 0 !important;
  padding: 0 !important;
}

.leftbar-title-label,
.leftbar-desc-label {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $dark-gray;
}

.leftbar-voice-label {
  text-transform: capitalize;
  width: auto !important;
  font-family: var(--font-condensed-bold);
  padding-left: 0 !important;
  padding-top: 0.9rem !important;
  padding-bottom: 0 !important;
  padding-right: 13px !important;
}

.leftbar-voice-label,
.leftbar-list-btn {
  font-size: 16px !important;
  line-height: 28px !important;
  letter-spacing: 0.006em !important;
  color: $smoky-black !important;
}

.leftbar-filter-content {
  display: flex !important;
  align-items: center !important;
  padding-bottom: 1rem !important;
}

.leftbar-list-content ul {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.leftbar-list-btn {
  font-family: var(--font-condensed) !important;
  text-transform: uppercase;
  border: none !important;
  padding-left: 1rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.leftbar-list-btn-active {
  border: 1.5px solid $smoky-black !important;
  border-radius: 60px !important;
  padding-left: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1rem !important;
}

.leftbar-list-content li {
  padding-bottom: 1rem !important;
}

.leftbar-close-area-text {
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $smoky-black;
  margin-bottom: 0 !important;
}

.close-btn:hover {
  opacity: 0.6;
}

.slide_from_left {
  transform: translateX(-100%);
  transition: 0.3s ease-in-out;
}
.slide_from_right {
  transform: translateX(0);
  transition: 0.3s ease-in-out;
}

@media (min-width: $width-large-min) {
  .leftbar-container {
    padding-left: 114px !important;
    padding-right: 71px !important;
    max-height: 80vh;
  }
}

@media (max-width: $width-medium-max) {
  .leftbar-container {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    overflow-y: auto;
    scrollbar-width: none;
    transition: 0.3s ease-in-out;
  }
  .leftbar-container::-webkit-scrollbar {
    display: none !important;
  }
  .leftbar-voice-label {
    text-transform: uppercase;
  }
}
