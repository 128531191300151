@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";
@import "../../../../../../assets/sass/button.scss";

.apparel-details-rightside-container {
  height: 100vh;
}

.apparel-details-container {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: $bg-color;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.apparel-details-rightside {
  padding-left: 20px !important;
  padding-top: 1rem !important;
  padding-right: 20px !important;
}

.apparel-details-title {
  font-family: var(--font-extended-bold);
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.004em;
  color: $smoky-black;
  margin-bottom: 1.5rem !important;
  padding: 0 !important;
}

.apparel-details-simple-text {
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $smoky-black;
  margin-bottom: 1.5rem !important;
  padding: 0 !important;
}

.apparel-details-btn-bynow {
  text-decoration: none !important;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
}

.apparel-details-bynow {
  border-top: 2px solid $feature-grey;
  border-bottom: 2px solid $feature-grey;
  margin-bottom: 1.5rem !important;
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.apparel-details-bynow-text {
  font-family: var(--font-regular);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
  color: $smoky-black;
}

.apparel-details-description {
  border-bottom: 2px solid $feature-grey;
  margin-bottom: 28px;
}

.apparel-details-total-price-label {
  font-family: var(--font-extended-bold);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $smoky-black;
}

.apparel-details-price {
  font-size: 24px !important;
  line-height: 34px;
  letter-spacing: 0.005em;
}
.apparel-details-total-price-value {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}

.apparel-details-price,
.apparel-details-total-price-value {
  font-family: var(--font-extended-bold);
  color: $smoky-black;
}

.apparel-details-code {
  font-family: var(--font-regular);
  text-transform: uppercase;
}
.apparel-details-code-value {
  font-family: var(--font-bold);
}
.apparel-details-code,
.apparel-details-code-value {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $smoky-black;
}

.apparel-details-brand {
  width: 133.94px !important;
  height: 40px !important;
}

.apparel-details-price-details {
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $smoky-black;
  margin-bottom: 24px !important;
  padding: 0 !important;
}

.apparel-details-total-price-rate {
  font-family: var(--font-regular);
  font-size: 14px;
  line-height: 18px;
  color: $smoky-black;
}

.footer {
  background: $white;
  position: fixed;
  bottom: 0;
}

.footerbtn {
  padding: 20px !important;
  background: $white !important;
}

.cancel-btn {
  height: 48px;
  padding: 0;
  margin-top: 16px !important;
}

.select-size {
  margin-right: 0 !important;
  margin-bottom: 24px !important;
}

.apparel-details-size-qty {
  flex-direction: column;
}

.return-btn {
  font-family: var(--font-regular);
}

@media (min-width: $width-large-min) {
  .apparel-details-container {
    overflow-y: hidden;
  }

  .apparel-details-rightside-container {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .apparel-details-title {
    font-size: 32px;
    line-height: 40px;
  }
  .apparel-details-rightside-back2gallery {
    display: flex !important;
    flex-direction: row !important;
    align-content: stretch !important;
    justify-content: flex-start !important;
    align-items: center !important;
    margin-bottom: 1.9rem !important;
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .apparel-details-rightside {
    padding-left: 2rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0 !important;
    padding-right: 3rem !important;
  }
  .select-size {
    margin-right: 24px !important;
    margin-bottom: 0 !important;
  }
  .apparel-details-size-qty {
    flex-direction: row;
  }
}

@media (max-width: $width-medium-max) {
  .apparel-details-rightside-back2gallery {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 16px !important;
    margin-top: 16px !important;
  }

  .apparel-details-bynow {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .apparel-details-rightside {
    padding-bottom: 152px !important;
  }
  .footer {
    background: transparent;
  }
}

.carousel-control {
  opacity: 0.9 !important;
}

.carousel-control:hover {
  opacity: 0.4 !important;
}

.carousel-control-hold {
  opacity: 0.4 !important;
}

.icon-size{
  width: 32px;
  height: 32px;
}

.close-area {
  border-bottom: 0 !important;
  justify-content: end !important;
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important;
}

@media (max-width: $width-medium-max) {
  .modal-remove-apparel {
    width: 100% !important;
  }
  .close-area {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin-top: 16px !important;
  }
  .modalObject {
    width: 742px !important;
    height: 391px;
  }
}

