@import "../../../common/assets/sass/button.scss";
@import "../../../common/assets/sass/sizing.scss";

.write-mobile-container {
  position: fixed;
  top: 0;
  z-index: 3000;
  background-color: $off-white;
}

.transparent {
  background: transparent;
  color: $smoky-black;
  padding-left: 0;
  padding-right: 0;
}

.gray,
.black {
  border-radius: 4px;
}

.gray {
  background: $light-grayish-red;
  color: $smoky-black;
  padding-left: 16px;
  padding-right: 16px;
}

.black {
  background: $smoky-black;
  color: $white;
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.007em;
}

.subtitle {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.amount,
.black .title {
  font-family: "ducati-extended-bold";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.006em;
}

.title,
.subtitle,
.amount,
.subtitle2,
.banned {
  text-align: center;
}

.subtitle2 {
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.banned {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $dark-gray;
  text-decoration: line-through;
}

.ds-form-container {
  background: $off-white;
  border-radius: 2px;
  border: 1px solid $feature-grey;
}

.ds-form-container-edit {
  border: 1px solid $dark-gray;
}

.ds-form-btn-link {
  font-family: "ducati-extended-bold";
  text-decoration: underline;
  color: $dark-gray;
  text-transform: capitalize;
}

.ds-form-btn-link:hover {
  color: $dark-gray;
}

.ds-form-input {
  border: 1px solid $dark-gray !important;
  border-radius: 4px !important;
}

.ds-form-selector {
  width: 86px !important;
}

.imghover:hover {
  opacity: 0.6;
}

.read-discount-value {
  font-family: "ducati-extended-bold";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.007em;
  color: $ducati-red;
  border-right: 1px solid $feature-grey;
}

.discountlabel {
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  text-align: center;
  color: $smoky-black;
}

.discounttitle {
  font-family: "ducati-extended-bold";
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
  text-align: center;
  color: $smoky-black;
  margin-top: 4rem;
}

@media (min-width: $width-large-min) {
  .transparent .title {
    font-family: "ducati-bold-extra";
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.006em;
  }
  .ds-form-btn-link {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.007em;
  }
  .amount,
  .banned,
  .subtitle2 {
    text-align: end;
  }
  .discountlabel {
    text-align: left;
  }
  .ds-form-input {
    max-width: 173px;
  }
}
