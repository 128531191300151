@import "../../assets/sass/button.scss";
@import "../../assets/sass/sizing.scss";

.footer-recap,
.footer-recap--scrambler {
  background: $smoky-black;
  text-align: center;
  padding: 10px 20px 17px 20px;
  z-index: 3;
}

.footer-recap--scrambler {
  background-color: $scrambler-yellow;
}

.font-title,
.font-title--scrambler {
  font-size: 20px !important;
  line-height: 26px;
  letter-spacing: 0.005em;
  display: inline !important;
  font-weight: 700;
}

.font-title {
  font-family: "ducati-extended" !important;
  color: $white;
}

.font-title--scrambler {
  font-family: "scrambler-ex";
  color: $black !important;
}

.font-title {
  font-family: "ducati", Arial;
  color: $white;
}

.font-title--scrambler {
  font-family: "scrambler-regular";
  color: $black !important;
}

.font-descr {
  font-family: "ducati" !important;
  color: $white;
  font-size: 16px;
}

.arrow {
  cursor: pointer;
  width: 24px;
}

.button-style {
  height: 56px;
  width: 100%;
}

.recapbar-mobile-content {
  background-color: $off-white;
  z-index: 3;
  max-height: calc(100vh - 83px);
  overflow-y: auto;
}

.recapbar-mobile-content::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.recapbar-mobile-content::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

// .shift-down-cart {
//   visibility: hidden;
//   height: 0;
//   transition: visibility 0.5s, height 0.5s ease-in-out;
// }

// .shift-up-cart {
//   visibility: visible;
//   height: calc(100vh - 83px);
//   transition: visibility 0.5s, height 0.5s ease-in-out;
// }

@media (min-width: $width-large-min) {
  .footer-recap {
    height: 83px;
    border-radius: 0 0 0 10px;
  }

  .footer-recap--scrambler {
    border-radius: 0 0 0 4px;
  }
  .recap-mtsmhp {
    border-radius: 0 0 0 10px;
  }
  .recapbar-mobile-content {
    max-height: calc(100vh);
  }
  .font-title,
  .font-title--scrambler {
    font-size: 24px !important;
    line-height: 34px;
  }
}

@media (max-width: $width-medium-max) {
  .recapbar-positioning {
    position: absolute;
    bottom: 0;
  }
  .recapbar-positioning-sticky {
    position: fixed;
    bottom: 0;
  }
}
