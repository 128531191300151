@import "src/common/assets/sass/colors";

.font_label_menu {
  color: $smoky-black;
  font-family: "ducati-extended-bold";
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.007em;
}

.all_font {
  color: $black;
  font-family: "ducati";
  font-size: 16px;
  line-height: 20px;
}

.margin_header {
  margin-top: 31px;
  margin-left: 49px;
}

.margin_body {
  margin-left: 29px;
  margin-right: 115px;
  -ms-overflow-style: none;
}

.margin_body::-webkit-scrollbar {
  display: none;
}

.padding_span {
  padding-left: 30px;
}

.button_x {
  height: auto;
  opacity: 1 !important;
}

.button_x:checked {
  border-color: transparent !important;
}

.font1 {
  font-family: "ducati";
  font-size: 16px;
  line-height: 20px;
  color: $feature-grey;
}

.font2 {
  font-family: "ducati";
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.border_top1 {
  margin-right: 44px;
  margin-top: 33px;
  border-top: 1px solid grey;
}

.margin_top2 {
  margin-top: 31px;
}

.margin_top3 {
  margin-top: 24px;
}

.margin_top4 {
  margin-top: 64px;
}

.margin5 {
  margin-left: 14px;
}

.cursor {
  cursor: pointer;
}

.borderSelected {
  border: 1px solid black;
  border-radius: 20px;
}

.margin_bottom {
  margin-bottom: 35px;
}

.text_truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.logged_border {
  border: 1px solid $ducati-red;
  border-radius: 60px;
}
