@import "../../../common/assets/sass/colors.scss";

.funding-form-part-border {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $dark-gray;
}

.funding-textarea {
  background: transparent;
  border: 1px solid $dark-gray;
  border-radius: 4px;
  min-height: 194px !important;
  padding: 1rem;
}
