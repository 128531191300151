.model-information-modal-title {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
}

.model-information-modal-text {
  margin-top: 29px;
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

@media (min-width: 992px) {
  .model-information-modal-content {
    width: 896px !important;
    padding: 52px 78px;
  }
}
