@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.info-msg-title-type1 {
  font-family: "ducati-bold", Arial, Helvetica, sans-serif;
}

.info-msg-title-type2 {
  font-family: "ducati-extended", Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.info-msg-title-type1,
.info-msg-title-type2 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $smoky-black;
}

.info-msg-title--scrambler {
  font-family: "scrambler-condensed-bold";
  color: $black;
  line-height: 26px;
  letter-spacing: 0.007em;
  text-decoration: underline;
}

.info-msg-collapse-button {
  background-color: transparent;
}

.collapse-container {
  background-color: $light-gray;
  font-family: "ducati", Arial, Helvetica, sans-serif;
  color: $smoky-black;
  margin-top: 8px;
}

.collapse-container.mto {
  font-family: "ducati", Arial, Helvetica, sans-serif;
  color: $smoky-black;
  margin-top: 0px;
  background: transparent !important;
}

.collapse-close-btn {
  background-color: transparent;
  width: 34px !important;
}

.collapse-close-img {
  height: 32px;
  width: 32px;
}

.collapse-content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.collapse-content-mto {
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}


.collapse-content--scrambler {
  font-family: "scrambler-regular";
}

.collapse-message {
  margin-top: 0px;
}

@media (min-width: $width-large-min) {
  .info-msg-title-type2 {
    font-family: "ducati-condensed", Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.008em;
    color: $black;
  }

  .info-msg-title--scrambler {
    line-height: 20px;
    letter-spacing: 0.008em;
  }
}
