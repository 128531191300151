@import "../../../../assets/sass/sizing.scss";
@import "../../../../assets/sass/button.scss";

.listing-apparel-container {
  padding-top: 26px;
}

.listing-apparel-overflow {
  width: 100%;
}

.listing-apparel-overflow::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.listing-apparel-overflow::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.listing-apparel-info-msg {
  margin-right: 32px !important;
}

.listing-apparel-content {
  margin-right: 16px;
  margin-bottom: 12px;
}

.listing-apparel-title {
  font-family: "ducati-extended-bold" !important;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.006em;
  color: $smoky-black !important;
}

.listing-apparel-simple-btn {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (min-width: $width-large-min) {
  .listing-apparel-overflow {
    overflow-y: scroll;
  }
  .listing-apparel-overflow-height-b2c {
    margin-top: 0px;
    height: calc(100vh - 76px - 26px - 50px - 83px - 29px);
  }
  .listing-apparel-overflow-height-b2b {
    height: calc(90vh - 83px - 36px - 26px - 1rem - 9px - 192px) !important;
  }
  .listing-apparel-title {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.005em;
  }
}
@media (max-width: $width-medium-max) {
  .listing-apparel-container {
    background: linear-gradient(180deg, $light-gray 1%, $bg-color 3%);
    position: relative;
    z-index: 0;
    overflow-y: scroll;
  }
  .stickybar {
    margin-top: 216px;
    margin-bottom: 83px;
  }
  .stickybarViewMode {
    margin-top: 140px;
    margin-bottom: 83px;
  }

  .listing-apparel-content {
    padding-bottom: 4px;
  }
}
