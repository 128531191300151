@import "../../../../../common/assets/sass/colors.scss";
@import "../../../../../common/assets/sass/sizing.scss";

.tab-content-title {
  font-family: "ducati-extended-bold";
  color: $smoky-black;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
}

.tab-content-title:first-letter {
  text-transform: capitalize;
}

.tab-content {
  border-bottom: 2px solid $smoky-black;
}

@media (min-width: $width-large-min) {
  .tab-content-title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.008em;
  }
  .tab-content {
    border-bottom: none;
  }
}
