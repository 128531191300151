$black: #000000;
$smoky-black: #0f0f0f;
$dark-gray: #666666;
$feature-grey: #bbbcbc;
$light-gray: #ebebeb;
$off-white: #fafafa;
$light-grayish-red: #f3eeee;
$white: #ffffff;
$ducati-red: #cc0000;
$scrambler-yellow: #ffc602;
$bg-color: #f5f5f5;
$bg-color-2: rgba(255, 255, 255, 0.75);
