@import "../../../assets/sass/colors.scss";

.header-navbar {
  white-space: nowrap;
  flex-wrap: nowrap !important;
  border-right: 1px solid #eaeaea;
}

.header-navbar-mobile {
  white-space: nowrap;
  flex-wrap: nowrap !important;
}

.header-navbar h6 {
  color: $smoky-black;
  font-family: "ducati-extended-bold" !important;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.007em;
}

.header-language h6 {
  font-size: 14px;
  line-height: 24px;
}

.margin_ham {
  margin-left: 42px;
  margin-right: 35px;
}

.margin_ham_mobile {
  margin-left: 42px;
}

.header-logo {
  cursor: pointer;
}
