@import "../../../../../common/assets/sass/colors.scss";
@import "../../../../../common/assets/sass/sizing.scss";
@import "../../../../../common/assets/sass/button.scss";

.incompatibility-modal__left-part {
  background-color: #fafafa;
}

.incompatibility-modal__right-part--scrambler {
  padding-top: 32px;
  background-color: $light-gray;
}

.height-buttons {
  height: 110px;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 27px;
  padding-bottom: 27px;
  border-top: 1px solid $feature-grey;
}

.margin1 {
  margin-top: 60px;
}

.margin2 {
  margin-top: 9px;
}

.margin4 {
  margin-right: 30px;
}

.margin5 {
  margin-top: 1px;
}

.margin6 {
  margin-left: 35px;
}

.incompatibility-modal__left-part__card-container {
  height: 322px;
}

.margin8 {
  margin-left: 83px;
}

.margin9 {
  //margin-top: 17px;
}

.margin10 {
  margin-bottom: 26px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
}

.margin10:first-of-type {
  margin-top: 14px;
}

.margin10:last-of-type {
  margin-bottom: 14px;
}

.incompatibility-modal__left-part__card {
  width: 217px;
  height: 322px;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
}

.incompatibility-modal__left-part__card-img {
  width: 216px;
  height: 216px;
}

.incompatibility-modal__left-part__title,
.incompatibility-modal__left-part__text,
.incompatibility-modal__left-part__card-text,
.incompatibility-modal__left-part__card-price,
.added-accessories-title,
.removed-accessories-title,
.font6 {
  color: $smoky-black;
}

.incompatibility-modal__left-part__title {
  margin-bottom: 0;
  font-family: var(--font-extended-bold);
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
}

.incompatibility-modal__left-part__title::first-letter {
  text-transform: capitalize;
}

.incompatibility-modal__left-part__card-body {
  height: auto;
  padding: 13px 13px 4px 13px !important;
  border-left: 1.2px solid $light-gray;
}

.incompatibility-modal__left-part__text {
  margin-bottom: 47px;
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
}

.font2 {
  font-family: var(--font-bold);
  font-size: 18px;
}

.incompatibility-modal__left-part__card-text {
  margin-bottom: 21px;
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
}

.incompatibility-modal__left-part__card-price {
  font-family: var(--font-bold-extra);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.font5 {
  font-family: var(--font-regular);
  font-size: 14px;
}

.added-accessories-title,
.removed-accessories-title,
.font6 {
  margin-bottom: 0;
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.added-accessories-title,
.removed-accessories-title {
  margin-bottom: 16px;
}

.button-style-1,
.button-style-2 {
  width: 100%;
  height: 56px;
}

.col-radio-button-choise {
  background: #fafafa;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #bbbcbc;
  border-radius: 4px 0px 0px 4px;
}

.radio-button-choise {
  border: 1px solid #0f0f0f !important;
  width: 24px !important;
  height: 24px !important;
}

.radio-button-choise:checked {
  background-image: url("../../../../assets/images/ModalIncompatibility/pointRadioModalIncompatibility.svg") !important;
  background-size: 10px 10px;
  background-color: transparent !important;
}

.radio-button-choise:focus {
  box-shadow: none !important;
}

.cards-container {
  width: 458px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07) !important;
}

.modal-incompatibility__footer {
  width: 1006px;
}

.choice_label {
  //styleName: Title 6;
  font-family: var(--font-extended-bold);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: $black;
}

.modal-header--scrambler {
  background-color: $light-gray;
}

.incompatibility-modal__left-part--scrambler {
  background-color: $light-gray;
}

.modal-colse-btn--scrambler {
  width: 15px;
}

@media (min-width: $width-large-min) {
  .init-modal {
    width: 1004px;
  }

  .incompatibility-modal__left-part {
    width: 403px !important;
    padding: 60px 25px 0 63px;
  }

  .height1 {
    max-height: 610px;
    min-height: 610px;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .incompatibility-modal__right-part {
    padding-left: 33px;
    width: 603px;
    height: 610px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .incompatibility-modal__right-part--larger-padding {
    padding-left: 83px;
  }
}

@media (max-width: $width-medium-max) {
  .incompatibility-modal__left-part {
    width: 100%;
    padding: 48px 20px 23px 20px;
  }

  .incompatibility-modal__left-part__text {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .incompatibility-modal__left-part__card-container {
    height: auto;
  }

  .incompatibility-modal__left-part__card {
    width: 100%;
    height: 124px !important;
    margin-bottom: 24px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  }

  .incompatibility-modal__left-part__card-img {
    width: auto !important;
    max-width: 124px !important;
    height: auto !important;
    max-height: 124px !important;
  }

  .incompatibility-modal__left-part__card-body {
    padding: 3.5px 7px 15px 16px !important;
  }

  .incompatibility-modal__right-part {
    margin-bottom: 22px;
    padding: 24px 20px 0 20px;
  }

  .col-radio-button-choise {
    height: 58px;
    border-width: 1px 1px 0px 1px;
    border-radius: 4px 4px 0px 0px;
    padding: 0 12px 0 15px;
  }

  .height-buttons {
    margin-top: 48px;
    padding: 0 8px;
    border: none;
  }

  .margin10 {
    width: 100%;
  }

  .margin10:first-of-type {
    margin-top: 26px;
  }

  .margin10:last-of-type {
    margin-bottom: 18px !important;
  }

  .button-style-1:last-of-type {
    margin-top: 8px;
  }

  .incompatibility-modal__left-part__card-body {
    overflow: hidden;
  }

  .incompatibility-modal__left-part__card-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button-style-1,
  .button-style-2 {
    height: 48px;
  }
}
