@import "../../../common/assets/sass/colors.scss";

.font-name {
  font-family: "ducati-extended-bold" !important;
  font-size: 18px !important;
  display: contents !important;
}

.accordion_item_name {
  margin-bottom: 0;
  font-family: "scrambler-ex-extra-bold";
  font-size: 18px;
}

.align {
  height: 80px;
}

.ElementChose {
  font-family: "ducati-bold-extra" !important;
  font-size: 16px;
  background: $light-gray;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
}

.accordion_item,
.accordion_item--scrambler {
  width: calc(100% - 31px);
  margin-left: 15px;
}

.accordion_item {
  border-top: 1px solid $feature-grey !important;
}

.accordion_item--scrambler {
  border-top: 1px solid $dark-gray !important;
}
