@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.nav-container,
.nav-container--scrambler {
  font-family: "ducati-bold" !important;
  overflow-x: hidden;
  overscroll-behavior-x: smooth;
  scrollbar-width: none;
  background-color: $white;
}

.nav-container--scrambler {
  font-family: "scrambler-bold" !important;
}

.nav-content {
  white-space: nowrap;
  flex-wrap: nowrap !important;
  height: 60px;
}

.nav-content-desktop {
  height: 60px;
  align-content: center;
}

.nav-button {
  font-family: "ducati-bold" !important;
  background-color: transparent !important;
  border-radius: 60px !important;
  color: $smoky-black !important;
  display: block !important;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: 0.007em !important;
}

.active-button {
  border: 1px solid $smoky-black !important;
}

@media (max-width: $width-medium-max) {
  .nav-container,
  .nav-container--scrambler {
    overflow-x: scroll;
    -webkit-mask-image: linear-gradient(to right, $off-white 80%, transparent);
    mask-image: linear-gradient(to right, $off-white 80%, transparent);
  }

  .nav-container::-webkit-scrollbar,
  .nav-container--scrambler::-webkit-scrollbar {
    display: none !important;
  }

  .nav-container ul li,
  .nav-container--scrambler ul li {
    padding-left: 8px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    display: block;
  }

  .StickyNavbar {
    position: fixed;
    top: 0;
    height: 60px;
    margin-top: 131px;
    background: white;
  }
  .StickyNavbarViewMode {
    position: fixed;
    top: 0;
    height: 60px;
    margin-top: 55px;
    background: white;
  }

  .navbar {
    z-index: 2;
  }
}
