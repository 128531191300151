@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.header-navbar-container {
  background-color: $white;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-top: 1px solid $light-gray;
}

.header-navbar-content {
  padding-left: 0.5rem !important;
  padding-right: 0 !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.header-navbar {
  flex-direction: row !important;
  overflow-x: scroll;
  scrollbar-width: none;
}

.header-navbar::-webkit-scrollbar {
  display: none !important;
}

.header-nav-item {
  font-family: var(--font-bold) !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.007em;
  color: $smoky-black !important;
  margin-right: 0.5rem;
  padding-left: 24px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-right: 24px !important;
  border-radius: 60px !important;
}

.header-nav-item-active {
  border: 1.5px solid $smoky-black !important;
  border-radius: 60px !important;
}

.header-filter-content {
  display: flex;
  justify-content: center;
  background: $white;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  width: 64px !important;
  border-left: 1px solid $light-gray;
}

.header-filter-btn {
  width: auto !important;
  border: none !important;
}
.header-navbar-container {
  white-space: nowrap;
}

.shadow {
  box-shadow: none;
}

@media (min-width: $width-large-min) {
  .header-navbar-container {
    background-color: transparent;
    padding: 0;
    border-top: 0;
  }
  .shadow {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .header-navbar {
    overflow: hidden !important;
  }
  .header-navbar-content {
    border: none;
  }
  .header-filter-content {
    display: none;
  }
}
@media (max-width: $width-medium-max) {
  .header-navbar-container {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .StickyNavbar {
    position: fixed;
    top: 0;
    z-index: 1;
    height: 65px;
    margin-top: 131px !important;
    background: white;
  }
  .StickyNavbarViewMode {
    position: fixed;
    top: 0;
    z-index: 1;
    height: 65px;
    margin-top: 55px !important;
    background: white;
  }
  .header-filter-content {
    max-height: 59px;
  }
}
