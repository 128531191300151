@import "../../../assets/sass/colors.scss";
@import "../../../assets/sass/sizing.scss";

.selector-bar-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.selector-bar-content {
  display: flex !important;
  width: fit-content;
  background: $white;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.1),
    0px 15px 20px -10px rgba(0, 0, 0, 0.2);
  border-radius: 21px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (max-width: $width-medium-max) and (orientation: portrait) {
  .selector-bar-container {
    display: none !important;
  }
}

.selector-list-ul {
  list-style-type: none !important;
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
}

.selector-list-li {
  margin: 0 !important;
}

.selector-bar-content img {
  max-width: 100%;
  height: auto;
  padding: 0.5rem;
}

.max-width-arrow {
  max-width: 85% !important;
}

.loading_bar {
  min-width: 100px;
  height: 5px;
  border-radius: 4px;
  background-color: $feature-grey;
  position: relative;
  display: flex;
  width: 75%;
}

.loading_bar_progress,
.loading_bar_progress--scrambler {
  width: 0%;
  height: 100%;
  border-radius: 4px;
  background-color: $ducati-red;
  animation: loading-animation 0.7s infinite;
}

.loading_bar_progress {
  background-color: $ducati-red;
}

.loading_bar_progress--scrambler {
  background-color: $scrambler-yellow;
}

.selector-visibility-icon {
  width: 36px;
  height: 36px;
}

@keyframes loading-animation {
  0% {
    width: 0%;
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  }
  25% {
    clip-path: polygon(0 0, 0 100%, 20px 100%, 20px 0);
  }
  50% {
    width: 100%;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
  100% {
    width: 0%;
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 0);
  }
}

.selector_transparency {
  background-color: rgba($color: $white, $alpha: 0.85);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
