@import "../../../../common/assets/sass/colors.scss";
@import "../../../../common/assets/sass/sizing.scss";

.form-card-container,
.form-card-container--scrambler {
  background-color: $white;
  border-radius: 2px;
}

.form-card-container {
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
}

.form-card-container--scrambler {
  box-shadow: 10px 3px 10px rgba(0, 0, 0, 0.05);
}

.form-card-title,
.form-card-title--scrambler,
.form-card-btn,
.form-card-btn--scrambler {
  text-transform: capitalize;
}

.form-card-text,
.form-card-text--scrambler,
.form-card-title,
.form-card-title--scrambler {
  margin-bottom: 0;
}

.form-card-title,
.form-card-price {
  font-family: "ducati-bold";
}

.form-card-title,
.form-card-btn {
  color: $smoky-black !important;
}

.form-card-title,
.form-card-title--scrambler {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
}

.form-card-price,
.form-card-price--scrambler {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
}

.form-card-btn,
.form-card-btn--scrambler {
  width: auto !important;
  text-decoration: underline !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.007em !important;
}

.form-card-title--scrambler,
.form-card-btn--scrambler {
  font-family: "scrambler-bold";
  color: $black;
}

.form-card-text,
.form-card-text--scrambler {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  text-transform: capitalize;
  width: auto !important;
}

.form-card-text {
  font-family: "ducati";
}

.form-card-text--scrambler {
  font-family: "scrambler-regular";
}

.form-card-btn {
  font-family: "ducati-extended-bold" !important;
}

.form-card-price--scrambler {
  font-family: "scrambler-bold";
}

.quantity-selector {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: $width-medium-max) {
  .form-card-btn-container {
    padding-top: 8px;
    border-top: 1px solid $light-gray;
  }
}

@media (max-width: $width-medium-max) {
  .form-card-title,
  .form-card-title--scrambler {
    margin-left: 13px !important;
    font-size: 16px;
    letter-spacing: 0.007em;
  }

  .form-card-container,
  .form-card-container--scrambler {
    display: flex;
    flex-direction: column;
  }
  .form-card-btn-container {
    padding: 0 !important;
  }

  .form-card-text,
  .form-card-text--scrambler {
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.008em;
    margin-left: 13px !important;
  }

  .card_container_modify {
    margin-left: 13px !important;
    margin-right: 13px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .form-card-title {
    font-family: "ducati-bold";
  }

  .card_title {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  .form-card-text {
    font-family: "ducati";
  }

  .form-card-price {
    font-family: "ducati-extended-bold";
    font-size: 14px !important;
  }

  .form-card-price--scrambler {
    font-size: 14px;
  }

  .quantitySpace {
    justify-content: space-between;
  }
  .quantitySpace div {
    margin-right: 16px !important;
  }
}
