@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";
@import "../../../../../../assets/sass/button.scss";

.gallery-footer-cotainer {
  background: $white;
  border-top: 1px solid $feature-grey;
  margin-top: auto !important;
}

.gallery-footer-content {
  padding-left: 0 !important;
}

.gallery-footer-label {
  font-family: "ducati";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
  color: $black;
}

.gallery-footer-back-btn {
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.resize-image {
  border: 1px solid $feature-grey !important;
  border-radius: 0 !important;
}

.gallery-footer-cotainer ul {
  list-style-type: none !important;
  display: flex;
  width: auto;
  padding-right: 0 !important;
}

.gallery-footer-cotainer ul li {
  margin-right: 0.5rem !important;
}

.cart-list {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.gallery-footer-label-center {
  text-align: center;
}

@media (min-width: $width-large-min) {
  .cart-list {
    justify-content: flex-start;
    align-items: center;
  }
  .gallery-footer-back-btn {
    margin-top: 0 !important;
  }
  .gallery-footer-content {
    padding-left: 116px !important;
  }
  .gallery-footer-label-center {
    text-align: start;
  }
}
