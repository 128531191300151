@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.content {
  margin-bottom: 4px;
  margin-right: 8px;
}

.prezzo,
.rata,
.price--scrambler {
  color: $black;
  line-height: 130%;
  letter-spacing: 0.05em;
}

.prezzo {
  font-family: "ducati-condensed-bold";
  font-size: 16px;
}

.price--scrambler {
  font-family: "scrambler-condensed-bold";
}

.rata {
  font-family: "ducati";
  font-size: 14px;
}

.price-incompatobility {
  padding-right: 9px;
  font-family: "ducati-bold-extra";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: $black;
}

@media (min-width: $width-large-min) {
  .prezzo,
  .price--scrambler {
    font-size: 18px;
    line-height: 120%;
    letter-spacing: normal;
  }

  .price-incompatobility {
    padding-right: 15px;
    font-family: "ducati-condensed-bold";
  }
}

@media (max-width: $width-xsmall-medium) {
  .prezzo,
  .price--scrambler {
    font-size: 14px;
  }
}

@media (max-width: $width-xsmall-min) {
  .prezzo,
  .price--scrambler {
    font-size: 13px;
  }
}
