@import "../../assets/sass/colors.scss";

.row-div {
  height: 100vh;
}

.img-style {
  height: 44px !important;
  width: auto;
}

.title {
  font-family: "ducati-extended-bold";
  font-size: 32px;
}

.page-description {
  font-family: "ducati";
}

.page-description,
.title {
  color: #000000;
}

.btn-style {
  height: 56px;
  padding: 12px 16px !important;
  border-color: $smoky-black !important;
  border-radius: 4px !important;
  font-family: "ducati-bold" !important;
  font-size: 16px !important;
  color: $smoky-black !important;

  &:hover {
    background-color: transparent !important;
  }
}

.service_page_container {
  top: 0;
  position: fixed;
  width: 100%;
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
  }
}
