@import "./colors.scss";

.btn-transparent,
.btn-transparent--scrambler {
  background: transparent;
}

.btn-black,
.btn-yellow,
.btn-disabled-scrambler,
.btn-grey,
.btn-feature-grey {
  border: none;
  text-transform: uppercase;
}

.btn-black,
.btn-yellow,
.btn-disabled-scrambler,
.btn-transparent--scrambler,
.btn-grey,
.btn-feature-grey {
  font-family: "scrambler-condensed-bold";
}

.btn-yellow,
.btn-grey {
  color: $black;
  text-align: center;
}

.btn-transparent {
  border: 1px solid $smoky-black;
  color: $smoky-black !important;
}

.btn-transparent--scrambler {
  border: 2px solid $black;
  color: $black !important;
}

.btn-red {
  background: $ducati-red;
}

.btn-gray {
  background: $dark-gray;
}

.btn-black {
  background-color: $black;
  border: none;
  color: $white;
  text-transform: uppercase;
}

.btn-yellow {
  background-color: $scrambler-yellow;
}

.btn-disabled-scrambler {
  background-color: $feature-grey;
  color: $white;
}

.btn-grey {
  background-color: $light-gray;
}

.btn-feature-grey {
  background-color: $feature-grey;
}

.btn-red,
.btn-gray,
.btn-feature-grey {
  color: $off-white !important;
  border: 0;
}

.btn-red,
.btn-gray,
.btn-transparent,
.btn-transparent--scrambler,
.btn-feature-grey {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 2px;
}

.btn-red,
.btn-gray,
.btn-transparent,
.btn-feature-grey {
  font-family: "ducati-condensed-bold-extra";
}

.btn-transparent:hover,
.btn-transparent--scrambler:hover {
  opacity: 0.6;
}

.btn-red:hover {
  background: linear-gradient(#ff000b, $ducati-red);
}

.btn-gray:hover {
  background: linear-gradient($feature-grey, $dark-gray);
}

.btn-underline {
  background: transparent;
  font-family: "ducati-bold";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.008em;
  text-decoration: underline;
  border: none;
}
