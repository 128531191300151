@import "../../../../../assets/sass/colors.scss";
@import "../../../../../assets/sass/sizing.scss";

.popover-container {
  position: relative;
}

.popover-list {
  // max-height: 600px;
  overflow-y: auto;
}

// .popover-content {
//   position: absolute;
//   top: 0;
//   left: 140%;
//   transform: translate(0, -10%);
//   background-color: rgba($color: $white, $alpha: 0.7);
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   z-index: 1;
// }

.popover-list > div {
  margin-top: 16px;
  margin-bottom: 16px;
}

.loadingStyle {
  left: 32% !important;
  top: 36% !important;
}

@media (max-width: $width-medium-max) {
  .popover-list > div {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .d-content {
    margin-left: 30px !important;
  }

  .loadingStyle {
    left: 0% !important;
    top: 29% !important;
  }
}

.popover-item {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
  padding: 5px;
  border: 1.5px solid transparent;
}

.active {
  border: 1.5px solid #000000;
  border-radius: 5px;
  opacity: 1;
}

.popover-item-img {
  border-radius: 3px;
  width: 75px !important;
  height: 45px !important;
}

.d-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popover-trigger {
  position: relative;
  z-index: 1;
}

.popover-trigger-active button {
  border-top: 2px solid $black;
  border-bottom: 2px solid $black;
}

.popover-offcanvans {
  position: fixed;
  visibility: visible;
  width: 224px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: $white, $alpha: 0.75);
  padding: 10px 10px 10px 100px;
}
.popover-offcanvans-open {
  transform: none;
  transition: transform 1s ease-in-out;
}
.popover-offcanvans-close {
  transform: translateX(-500%);
  transition: transform 1s ease-in-out;
}

@media (min-width: $width-large-min) {
  .popover-offcanvans {
    width: 300px;
    padding: 10px 10px 10px 100px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: -1;
  }
  .popover-offcanvans-close {
    transform: translateX(-100%);
    transition: transform 1s ease-in-out;
  }
  .popover-item-img {
    width: 158px;
    height: 95px;
  }
}
