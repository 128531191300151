@import "../../../common/assets/sass/colors.scss";

.ducati-accordion-container {
  border-top: 1px solid $feature-grey;
  border-bottom: 1px solid $feature-grey;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 0;
}

.ducati-accordion-button {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
}

.ducati-accordion-light-grayish-red {
  background: $light-grayish-red;
}

.ducati-accordion-white {
  background: $white;
}
