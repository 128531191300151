@import "../../assets/sass/colors.scss";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext,
.no-price-tooltip {
  visibility: hidden;
  width: auto;
  background-color: $light-gray;
  color: $smoky-black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-family: "ducati-condensed";
  font-size: 16px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .no-price-tooltip {
  visibility: visible;
  display: inline-block;
  z-index: 2;
}

.tooltip:hover .tooltiptext {
  white-space: nowrap;
}

.tooltipww:hover .tooltiptext,
.tooltipww:hover .no-price-tooltip {
  visibility: visible;
  display: inline-block;
  z-index: 2;
}

.tooltipww:hover .tooltiptext {
  white-space: nowrap;
}

.top {
  width: 200px;
  bottom: 100%;
  left: 50%;
  transform: translateX(
    -100px
  ); /* Use half of the width (120/2 = 60), to center the tooltip */
}

.left {
  top: calc(60% / 2);
  right: 105%;
}

.bottom {
  width: auto;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.right {
  top: calc(60% / 2);
  left: 130%;
}

.tooltipww {
  position: relative;
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 10px;
}
