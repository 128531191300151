@font-face {
  font-family: "ducati";
  font-style: normal !important;
  font-weight: 400 !important;
  font-display: fallback;
  src: local("ducati"),
    url(https://media.ducati.com/fonts/DucatiStyle_W_Rg.woff) format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyle_W_Rg.woff2) format("woff2");
}

@font-face {
  font-family: "ducati-bold";
  font-style: normal !important;
  font-weight: 700 !important;
  font-display: fallback;
  src: local("ducati-bold"),
    url(https://media.ducati.com/fonts/DucatiStyle_W_Bd.woff) format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyle_W_Bd.woff2) format("woff2");
}

@font-face {
  font-family: "ducati-bold-extra";
  font-style: normal !important;
  font-weight: 800 !important;
  font-display: fallback;
  src: local("ducati-bold-extra"),
    url(https://media.ducati.com/fonts/DucatiStyle_W_Bd.woff) format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyle_W_Bd.woff2) format("woff2");
}

@font-face {
  font-family: "ducati-extended";
  font-style: normal !important;
  font-weight: 400 !important;
  font-display: fallback;
  src: local("ducati-extended"),
    url(https://media.ducati.com/fonts/DucatiStyleExt_W_Rg.woff) format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyleExt_W_Rg.woff2)
      format("woff2");
}

@font-face {
  font-family: "ducati-extended-bold";
  font-style: normal !important;
  font-weight: 700 !important;
  font-display: fallback;
  src: local("ducati-extended-bold"),
    url(https://media.ducati.com/fonts/DucatiStyleExt_W_Bd.woff) format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyleExt_W_Bd.woff2)
      format("woff2");
}

@font-face {
  font-family: "ducati-condensed";
  font-style: normal !important;
  font-weight: 400 !important;
  font-display: fallback;
  src: local("ducati-condensed"),
    url(https://media.ducati.com/fonts/DucatiStyleCond_W_Bd.woff) format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyleCond_W_Bd.woff2)
      format("woff2");
}

@font-face {
  font-family: "ducati-condensed-bold";
  font-style: normal !important;
  font-weight: 700 !important;
  font-display: fallback;
  src: local("ducati-condensed-bold"),
    url(https://media.ducati.com/fonts/DucatiStyleCond_W_XBd.woff)
      format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyleCond_W_XBd.woff2)
      format("woff2");
}

@font-face {
  font-family: "ducati-condensed-bold-extra";
  font-style: normal !important;
  font-weight: 800 !important;
  font-display: fallback;
  src: local("ducati-condensed-bold-extra"),
    url(https://media.ducati.com/fonts/DucatiStyleCond_W_XBd.woff)
      format("woff"),
    url(https://media.ducati.com/fonts/DucatiStyleCond_W_XBd.woff2)
      format("woff2");
}

@font-face {
  font-family: "scrambler-regular";
  font-weight: 400 !important;
  src: url("./common/assets/fonts/SCRDucatiStyle_Rg.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-bold";
  font-weight: 700 !important;
  src: url("./common/assets/fonts/SCRDucatiStyle_Bd.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-extra-bold";
  font-weight: 700 !important;
  src: url("./common/assets/fonts/SCRDucatiStyle_XBd.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-condensed";
  font-weight: 400 !important;
  src: url("./common/assets/fonts/SCRDucatiStyleCd_Rg.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-condensed-bold";
  src: url("./common/assets/fonts/SCRDucatiStyleCd_Bd.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-condensed-bold-italic";
  font-weight: 400 !important;
  src: url("./common/assets/fonts/SCRDucatiStyleCd_XBdIt.ttf")
    format("truetype");
}

@font-face {
  font-family: "scrambler-ex";
  font-weight: 400 !important;
  src: url("./common/assets/fonts/SCRDucatiStyleEx_Rg.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-ex-bold";
  font-weight: 700 !important;
  src: url("./common/assets/fonts/SCRDucatiStyleEx_Bd.ttf") format("truetype");
}

@font-face {
  font-family: "scrambler-ex-extra-bold";
  font-weight: 800 !important;
  src: url("./common/assets/fonts/SCRDucatiStyleEx_XBd.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "ducati";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

:root {
  --used-font: ducati-font;
  --font-regular: "ducati";
  --font-bold: "ducati-bold";
  --font-bold-extra: "ducati-bold-extra";
  --font-extended: "ducati-extended";
  --font-extended-bold: "ducati-extended-bold";
  --font-condensed: "ducati-condensed";
  --font-condensed-bold: "ducati-condensed-bold";
  --font-condensed-bold-extra: "ducati-condensed-bold-extra";
}

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.carousel-control-prev-icon {
  background-image: url("./common/assets/images/ArrowLeft.svg") !important;
  width: 12px !important;
  height: 24px !important;
  color: #666666 !important;
  opacity: 1 !important;
}
.carousel-control-next-icon {
  background-image: url("./common/assets/images/ArrowRigth.svg") !important;
  width: 12px !important;
  height: 24px !important;
  color: #666666 !important;
  opacity: 1 !important;
}
