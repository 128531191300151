@import "../../../common/assets/sass/button.scss";
@import "../../../common/assets/sass/sizing.scss";

.bg_white {
  background-color: $white;
}

.bg_offwhite {
  background-color: $off-white;
}

.bike-name {
  font-family: var(--font-extended-bold);
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.0025em;
  text-transform: capitalize;
  color: $smoky-black;
  text-align: right;
}

.button-style {
  width: 100%;
  min-height: 48px;
  padding: 12px !important;
  text-decoration: none;
}

.tab-title {
  font-family: var(--font-extended-bold);
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: $black;
}

.tab-subtitle {
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  text-align: left;
}

.li-decimal {
  list-style-type: decimal;
}

.tabs {
  background-color: $white;
}

.configuration-code {
  margin-bottom: 0;
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.estiamtive-card {
  padding: 16px 0 16px 0;
  border-bottom: 0.5px solid $feature-grey;
}

.border_top {
  padding: 16px 0 16px 0;
  border-top: 0.5px solid $feature-grey;
}

.estiamtive-card__category,
.estiamtive-card__title,
.estiamtive-card__description {
  margin-bottom: 1px;
  font-family: var(--font-regular);
}

.estiamtive-card__category,
.estiamtive-card__description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $dark-gray;
}

.estiamtive-card__title,
.estiamtive-card__price {
  font-size: 16px;
  letter-spacing: 0.007em;
  color: $smoky-black;
}

.estiamtive-card__title {
  line-height: 26px;
}

.estiamtive-card__price {
  margin: 7px 0 0 0;
  font-family: var(--font-bold);
  line-height: 22px;
  text-align: end;
}

.estiamtive-card__description {
  list-style-type: none;
}

.estimative-container__header {
  font-family: var(--font-extended-bold);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
}

.price-recap__price-main-title {
  margin-bottom: 0;
  font-family: var(--font-regular);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
  color: $smoky-black;
}

.price-recap__price-sub-title {
  display: block;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.price-recap__price-value {
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
  color: $smoky-black;
}

.price-recap__price-icon,
.editorial-card-title {
  font-family: var(--font-extended-bold);
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
  color: $smoky-black;
}

.editorial-card-img {
  width: 100%;
  max-width: 340px;
  object-fit: contain;
}

.editorial-card-title {
  color: $black;
  text-transform: capitalize;
}

.editorial-card-label {
  font-family: var(--font-bold);
  color: $dark-gray;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}

.editorial-card-btn {
  max-width: 148px !important;
  padding: 12px 16px !important;
  font-family: "ducati-extended-bold" !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.007em;
  color: $smoky-black !important;
}

.editorial-card-btn:link,
.editorial-card-btn:visited,
.editorial-card-btn:active {
  color: $smoky-black !important;
}

@media (min-width: $width-large-min) {
  .button-style {
    width: 300px;
  }
}
