@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.trade-from-input {
  width: 100%;
  margin-top: 12px;
}

.trade-from-input-small,
.trade-from-select-small:first-of-type {
  width: calc(70% - 8px);
  margin-top: 14px;
}

.trade-from-select-small:last-of-type {
  width: 30%;
  margin-top: 14px;
}

.trade-form-input-container {
  margin-top: 14px;
}

.trade-form-textarea-container {
  margin-top: 40px;
  padding-top: 26px;
  border-top: 1px solid $feature-grey;
}

.trade-form-textarea {
  width: 100%;
  min-height: 194px;
  margin-top: 15px;
  display: block;
  padding: 1rem;
  border: 1px solid $dark-gray;
  border-radius: 4px;
  outline: none;
  background: transparent;
}

.trade-from-option {
  text-transform: capitalize;
}

.borders {
  border-bottom: 1px solid $feature-grey;
}

@media (min-width: $width-large-min) {
  .trade-from-input {
    width: 354px;
    margin-top: 0;
  }

  .trade-from-input-small,
  .trade-from-select-small:first-of-type,
  .trade-from-select-small:last-of-type {
    width: 173px;
    margin-top: 14px;
  }
}
