@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";

.header-container {
  background: $off-white;
}

.header-content {
  flex-direction: column-reverse;
}

.header-title {
  text-align: center !important;
}

.title {
  font-family: var(--font-extended-bold);
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
  color: $smoky-black;
  margin-bottom: 2rem !important;
}

.header-close-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.header-close-btn {
  border: none !important;
  padding-top: 0.6rem !important;
  padding-left: 0 !important;
}

.header-close-btn-first {
  border: none !important;
  padding-top: 0.6rem !important;
  padding-left: 0rem !important;
  opacity: 0.6;
}


.header-close-btn:hover {
  opacity: 0.6;
}

@media (min-width: $width-large-min) {
  .header-container {
    background: $bg-color;
    padding-left: 114px !important;
    padding-top: 0 !important;
    padding-bottom: 41px !important;
    padding-right: 24px !important;
  }
  .header-content {
    flex-direction: row;
  }
  .header-title {
    text-align: left !important;
  }
  .title {
    color: $black;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.004em;
    padding-left: 0 !important;
    padding-top: 34px;
    padding-bottom: 28px;
    padding-right: 0 !important;
    margin-bottom: 1rem !important;
  }
  .header-close-area {
    padding-bottom: 0;
  }
}
