@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.carousel-container {
  height: 500px;
  background-color: $white;
  @media (min-height: 2000px) {
    height: 100%;
  }
}

.dimension-carousel {
  height: auto;
  width: auto !important;
  max-height: 100vh !important;
  max-width: 100%;
}

.margin {
  bottom: initial !important;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 237px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.margin_background {
  background: linear-gradient(
    180deg,
    rgba(15, 15, 15, 0.54) 0%,
    rgba(15, 15, 15, 0) 50.15%
  ) !important;
}

.align-button {
  align-content: end !important;
  display: grid !important;
  bottom: 27px !important;
}
.scrollCarousel {
  width: 100% !important;
  border-radius: 10px !important;
}
.marginscrollCarousel {
  margin-left: 32px !important;
  margin-right: 32px !important;
  display: flex;
  width: 100%;
}

.carousel-control {
  opacity: 0.9 !important;
}

.carousel-control:hover {
  opacity: 0.4 !important;
}

.carousel-control-hold {
  opacity: 0.4 !important;
}

@media (min-width: $width-large-min) {
  .carousel-container {
    height: 100vh;
  }
  .margin {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 337px;
  }
}

@media (max-width: $width-xsmall-max) {
  .carousel-control,
  .carousel-control-hold {
    margin-left: 19px;
    margin-right: 19px;
  }
}
