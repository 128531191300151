@import "../../../../../common/assets/sass/colors.scss";
@import "../../../../../common/assets/sass/sizing.scss";

.title {
  max-width: 90% !important;
}

.font-title {
  font-family: "ducati-extended-bold", Arial;
  font-size: 32px;
}

.font-subtitle {
  font-family: "ducati-extended-bold", Arial;
  font-size: 24px;
}

.font-subdescr {
  font-family: "ducati", Arial;
  font-size: 16px !important;
}

.content {
  padding-top: 26px;
}

.card_scrollbar {
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.card_scrollbar_b2c {
  max-height: calc(100vh - 76px - 26px - 60px - 24px - 83px);
}
.card_scrollbar_color_b2c {
  max-height: calc(100vh - 76px - 26px - 60px - 24px - 83px - 96px);
}
.card_scrollbar_b2b {
  max-height: calc(100vh - 76px - 161.67px - 26px - 60px - 24px - 83px);
}

.card_scrollbar_b2b_notes {
  max-height: calc(100vh - 76px - 200.67px - 26px - 60px - 24px - 83px);
}

.card_scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.card_scrollbar::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

@media (max-width: 991px) {
  .font-title {
    font-size: 32px !important;
  }

  .font-subtitle {
    font-size: 20px !important;
  }
}

@media (max-width: $width-medium-max) {
  .content {
    background-color: $off-white;
    position: relative;
    z-index: 0;
  }
  .stickybar {
    margin-top: 191px;
    padding-bottom: 83px;
  }
  .stickybarViewMode {
    margin-top: 115px;
    margin-bottom: 83px;
  }
  .card_scrollbar {
    max-height: none;
    overflow-y: none;
  }
}
