@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.configurator-container,
.configurator-content {
  min-height: 90vh;
}

.height-configurator {
  min-height: 90vh;
  margin-top: 10rem;
  align-content: center;
}

.font-transitor-title {
  font-family: "ducati-extended", Arial;
  font-size: 40px !important;
}

.font-transitor-desc {
  font-family: "ducati", Arial;
  font-size: 18px !important;
}

.configurator-content,
.configurator-content--sticky {
  background-color: $off-white;
  display: flex;
}

@media (min-width: $width-large-min) {
  .price-info-modal {
    width: 896px !important;
    height: 710px;
  }
  .configurator-container,
  .configurator-content {
    height: calc(100vh - 76px);
  }
}

@media (max-width: $width-medium-max) {
  .configurator-content {
    height: calc(100vh - 76px);
  }

  .configurator-content--sticky {
    height: 100vh;
  }

  .configurator-content-container {
    overflow-y: scroll;
  }
}

@media (max-width: $width-medium-max) and (orientation: landscape) {
  .configurator-content {
    height: 100vh;
  }
}
