@import "../../../assets/sass/colors.scss";
@import "../../../assets/sass/sizing.scss";

.title {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
  color: $dark-gray;
  width: auto !important;
}

.btnlabel {
  font-family: "ducati-bold" !important;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: 0.007em;
  color: $smoky-black;
}

.btnlabel-active {
  border: 1px solid $smoky-black !important;
  border-radius: 60px !important;
}

.expanded-menu-container {
  background-color: $white;
  border-left: 1px solid $light-gray;
  height: 100%;
}

.expanded_menu_content {
  background-color: $white;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  text-align: start;
  font-family: "ducati" !important;
  z-index: 999;
  transition: 0.3s ease-in-out;
}

.more-button {
  height: 65px !important;
}

.expanded_menu_content li {
  list-style-type: none;
}

.expanded-menu-button {
  background-color: transparent !important;
  border-radius: 60px !important;
  border: 1px solid transparent !important;
  display: block !important;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) !important;
  text-align: center !important;
}

.active-button {
  border: 1px solid $smoky-black !important;
}

.expanded-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-close-modal {
  display: contents;
}

.btn-close-modal img:hover {
  opacity: 0.6;
}

@media (min-width: $width-large-min) {
  .expanded-menu-container {
    display: none;
  }
}
.slide_from_left {
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
}
.slide_from_right {
  transform: translateX(0);
  transition: 0.3s ease-in-out;
}
