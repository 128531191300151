@import "../../../../common/assets/sass/colors.scss";

.form-card-container {
  background-color: $white;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.form-card-text,
.form-card-title,
.form-card-btn {
  font-family: "ducati-bold";
  color: $smoky-black;
  text-transform: capitalize;
}

.form-card-text,
.form-card-title {
  margin-bottom: 0;
}

.form-card-title {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
}

.form-card-text {
  line-height: 22px;
  letter-spacing: 0.007em;
}

.form-card-btn {
  text-decoration: underline;
  padding: 0;
  border-radius: 0;
}

.form-card-btn:first-of-type {
  padding-right: 6px;
  border-right: 1px solid $smoky-black;
}

.form-card-btn:last-of-type {
  padding-left: 6px;
}

@media (max-width: 992px) {
  .form-card-btn-container {
    padding-top: 8px;
    border-top: 1px solid $feature-grey;
  }

  .form-card-container {
    padding: 12px 0;
  }
}
