@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.recap-notes-form {
  display: grid;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 42px;
  margin-bottom: 22px;
}

.recap-notes-form textarea {
  background: transparent;
  border: 1px solid $dark-gray;
  border-radius: 4px;
  min-height: 194px !important;
  padding: 1rem;
  margin-top: 1rem;
}

.simple-label {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  margin: 0;
  padding: 0;
  color: $dark-gray;
}

@media (max-width: $width-medium-max) {
  .simple-label {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.007em;
  }
}
