@import "../../../../common/assets/sass/button.scss";

@media print {
  @page {
    margin-top: 30px;
  }
  @font-face {
    font-family: "ducati";
    font-style: normal !important;
    font-weight: 400 !important;
    font-display: fallback;
    src: local("ducati"),
      url(https://media.ducati.com/fonts/DucatiStyle_W_Rg.woff) format("woff"),
      url(https://media.ducati.com/fonts/DucatiStyle_W_Rg.woff2) format("woff2");
  }
  .print_option {
    border-bottom: 1px solid $feature-grey;
  }
  .print_title_header,
  .print_title_desc,
  .print_title,
  .print_main,
  .print_subtitle,
  .print_price,
  .print_total_price_label,
  .print_total_price_sum_label,
  .print_total_price_sum_sublabel {
    font-family: "ducati";
    color: $black;
  }
  .print_avoid {
    page-break-inside: avoid;
  }
  .option_margin {
    margin-top: 24px;
  }
  .option_margin2 {
    margin-bottom: 24px;
  }
  .print_title_header {
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.0025em;
  }
  .print_title_desc {
    font-size: 9px;
    line-height: 15px;
    letter-spacing: 0.0025em;
  }
  .print_title {
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 0.0025em;
  }
  .print_main {
    font-size: 8px;
    line-height: 15px;
    letter-spacing: 0.0025em;
  }
  .print_subtitle,
  .print_price {
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.0025em;
  }
  .print_code {
    font-size: 6px;
    line-height: 8px;
    letter-spacing: 0.0025em;
  }
  .print_total_price_label {
    font-size: 8px;
    line-height: 15px;
    letter-spacing: 0.0025em;
  }
  .print_total_price {
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.0025em;
  }
  .print_total_price_sum_label {
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.0025em;
  }
  .print_total_price_sum_sublabel {
    font-size: 8px;
    line-height: 11px;
    letter-spacing: 0.0025em;
  }
  .print_total_price_sum {
    font-size: 12px;
    line-height: 9px;
    letter-spacing: 0.0025em;
  }
  .print_price span {
    color: $dark-gray;
  }
  .print_code {
    font-family: "ducati";
    color: $dark-gray;
  }

  .print_priceing_content_details {
    font-family: "ducati";
    background-color: $off-white;
  }
  .print_total_price_label {
    font-family: "ducati";
    color: $smoky-black;
  }
  .print_total_price,
  .print_total_price_sum {
    font-family: "ducati";
    color: $smoky-black;
  }
  .print_recap_aftermarket {
    background-color: $off-white;
    border-radius: 4px;
  }
  .print_recap_details_image {
    width: 122px !important;
    height: 122px !important;
  }
  .print_recap_details_color {
    width: 40px !important;
    height: 40px !important;
  }
  .print_recap_aftermarket_title {
    font-family: "ducati-bold" !important;
    font-size: 9px;
  }
  .print_recap_aftermarket_description {
    font-family: "ducati" !important;
    font-size: 8px;
  }
  .print_recap_details_title {
    font-family: "ducati" !important;
    font-size: 16px;
  }
  .print_recap_more_info_title {
    font-family: "ducati-bold-extra" !important;
    font-size: 5px;
    word-wrap: break-word;
  }
  .print_recap_more_info_desc {
    font-family: "ducati" !important;
    font-size: 5px;
    word-wrap: break-word;
  }
  .print_hero_image {
    background-color: $off-white;
  }
  .print_rigth_side {
    font-family: "ducati" !important;
    font-size: 8px !important;
  }
  .print_rigth_side p {
    font-family: "ducati" !important;
    font-size: 8px !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  .print_rigth_side div {
    margin: 0px !important;
    padding: 0px !important;
  }

  .print_qrcode {
    width: 50px;
    height: 50px;
  }
  .print_recap_details_recap {
    width: 90% !important;
    margin: 0 auto !important;
  }

  .option_margin_element {
    margin-top: 4px !important;
  }
}
