@import "../../../../../common/assets/sass/colors.scss";
@import "../../../../../common/assets/sass/sizing.scss";

.font-title {
  font-family: "ducati-extended-bold", Arial;
  font-size: 40px !important;
}

.font-subtitle {
  font-family: "ducati-extended-bold", Arial;
  font-size: 24px !important;
}

.font-subdescr {
  font-family: "ducati", Arial;
  font-size: 16px !important;
}

.content {
  padding-top: 26px;
}
.grouped_card_scrollbar {
  width: 100%;
}

.grouped_card_scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.grouped_card_scrollbar::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.info_msg {
  margin-right: 32px !important;
}

@media (min-width: $width-large-min) {
  .incomaptibility-modal {
    width: 1006px !important;
  }
  .font-subtitle {
    height: 36px;
  }
  .grouped_card_scrollbar-height-b2c {
    max-height: calc(
      100vh - 83px - 36px - 26px - 1.5rem - 9px - 76px - 10px
    ); //90vh = heigth all - 83px (footer) - 36px(title) - 26px (padding top all) - 1rem (padding tittle) - 9px (margin on top title)
  }
  .grouped_card_scrollbar {
    overflow-y: scroll;
  }
  .grouped_card_scrollbar-height-b2b--note-displayed {
    max-height: calc(
      100vh - 83px - 36px - 26px - 1.5rem - 9px - 76px - 10px - 200.67px
    ); //90vh = heigth all - 83px (footer) - 36px(title) - 26px (padding top all) - 1rem (padding tittle) - 9px (margin on top title)
    // min-height: calc(90vh - 83px - 36px - 26px - 1rem - 9px - 192px) !important;
  }
  .grouped_card_scrollbar-height-b2b--no-note {
    max-height: calc(
      100vh - 83px - 36px - 26px - 1.5rem - 9px - 76px - 10px - 161.67px
    );
  }
}
@media (max-width: $width-medium-max) {
  .content {
    background-color: $off-white;
    position: relative;
    z-index: 0;
  }
  .stickybar {
    margin-top: 191px;
    margin-bottom: 83px;
  }
  .stickybarViewMode {
    margin-top: 115px;
    margin-bottom: 83px;
  }
}
