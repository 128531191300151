@import "../../../../common/assets/sass/sizing.scss";
@import "../../../../common/assets/sass/button.scss";

.border-scrambler {
  border-color: $dark-gray !important;
}

.font-card {
  font-family: "ducati" !important;
  font-size: 18px !important;
}

.font-card--scrambler {
  font-family: "scrambler-regular", Arial;
  color: $black;
  font-size: 18px;
}

.font-name {
  font-family: "ducati-bold" !important;
  font-size: 18px !important;
}

.font1 {
  font-family: "ducati-extended-bold" !important;
  font-size: 14px !important;
}

.font-price {
  font-family: "ducati-bold" !important;
  font-size: 16px !important;
}

.font-price--scrambler {
  font-family: "scrambler-regular", Arial;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: $black;
}

.font-price--scrambler {
  font-family: "scrambler-regular", Arial;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: $black;
}

.font-bundle {
  font-family: "ducati" !important;
  font-size: 16px !important;
  display: inline;
}

.font-aftermarket {
  font-family: "ducati" !important;
  font-size: 16px !important;
}

.font-accessori {
  font-family: "ducati" !important;
  font-size: 16px !important;
}

.font-consegna-prevista {
  font-family: "ducati-bold" !important;
  font-size: 16px !important;
}

.font-price-total {
  font-family: "ducati-bold" !important;
  font-size: 24px !important;
}

.font-price-total-title,
.font-price-total-title--scrambler {
  font-size: 18px !important;
}

.font-price-total-title {
  font-family: "ducati", Arial;
}

.font-price-total-title--scrambler {
  font-family: "scrambler-regular", Arial;
  color: $black;
}

.font-price-total-title-1 {
  font-family: "ducati" !important;
  font-size: 14px !important;
}

.font-total-price--scrambler {
  font-family: "scrambler-regular", Arial;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.font-total-price--scrambler {
  font-family: "scrambler-regular", Arial;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.modifica-rimuovi {
  font-family: "ducati-bold" !important;
  font-size: 16px !important;
  text-decoration: underline;
  cursor: pointer;
}

.font-title {
  font-family: var(--font-bold);
  font-size: 32px !important;
}

.font-title-recap {
  font-family: "ducati-bold" !important;
  font-size: 40px !important;
}

.font-title--scrambler {
  font-family: "scrambler-ex-extra-bold";
  font-size: 40px;
}

.font-title1-modal {
  font-family: "ducati-extended-bold" !important;
  font-size: 18px !important;
}

.font-descr1-modal {
  font-family: "ducati" !important;
  font-size: 14px !important;
}

.button-style {
  min-height: 48px;
  padding: 12px;
  text-decoration: none;
}

.bottom-row {
  border-top: 1px solid $light-gray;
  padding-top: 8px;
}

.font-name,
.font-consegna-prevista,
.modifica-rimuovi,
.font-aftermarket {
  margin-bottom: 0;
}

.overflow-x {
  // background-color: rgba($white, 0.75);
  overflow-x: hidden;
}

.font-card,
.font-name,
.font-price,
.font-bundle,
.font-aftermarket,
.font-accessori,
.font-price-total,
.font-price-total-title,
.font-price-total-title-1,
.modifica-rimuovi,
.font-title,
.font-title-recap {
  color: $smoky-black;
}

.contentCart,
.content_cart--scrambler {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 3rem;
}
.contentCart::-webkit-scrollbar,
.content_cart--scrambler::-webkit-scrollbar {
  width: 6px;
}

/* Stile del cursore della scrollbar */
.contentCart::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.content_cart--scrambler::-webkit-scrollbar-thumb {
  background-color: $black;
  border-radius: 4px;
}

.recap_image {
  background: #ffffff;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.recap_moto_all,
.recap_moto_all--scrambler {
  font-size: 16px;
  margin-top: 17px;
  margin-bottom: 29px;
  color: $dark-gray;
  text-decoration: underline;
  cursor: pointer;
}

.recap_moto_all {
  font-family: "ducati-extended", Arial;
}

.recap_moto_all--scrambler {
  font-family: "scrambler-ex", Arial;
}

.font-card-span {
  display: block;
  font-size: 14px !important;
}

.margin {
  padding-top: 7px !important;
}

.border {
  border-bottom: 1px solid $feature-grey;
  padding-bottom: 16px;
}

@media (min-width: $width-large-min) {
  .incomaptibility-modal {
    width: 1006px !important;
  }

  .button-style {
    width: 100%;
    max-width: 179px !important;
  }

  .button-style-recap {
    max-width: 255px !important;
    min-height: 48px;
    padding: 12px;
    text-decoration: none;
  }
  .contentCartHeight_b2b {
    max-height: calc(100vh - 76px - 19px - 192px - 24px - 112px - 83px);
  }
  .contentCartHeight {
    max-height: calc(100vh - 76px - 19px - 24px - 112px - 83px);
  }
}

@media (max-width: $width-medium-max) {
  .font-title-recap {
    font-family: "ducati-extended-bold" !important;
    font-size: 32px !important;
  }
  .font-card {
    font-family: "ducati-bold" !important;
    font-size: 16px !important;
  }

  .font-card--scrambler {
    font-size: 16px;
    font-weight: 700;
  }
  .font-card span,
  .font-card--scrambler span {
    font-weight: 400 !important;
  }
  .font-price-total {
    font-size: 20px !important;
  }
  .font-accessori {
    font-size: 14px !important;
  }

  .contentCart,
  .content_cart--scrambler {
    background-color: $off-white;
  }

  .button-style,
  .personalize-button-style {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }

  .print_recap_more_info_desc {
    font-family: "ducati" !important;
    font-size: 5px;
    word-wrap: break-word;
  }
}
