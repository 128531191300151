@import "../../../../common/assets/sass/sizing.scss";
@import "../../../../common/assets/sass/button.scss";

.qtn-hr-pre-content {
  width: 100%;
  justify-content: center;
}

.qtn-hr-main-content {
  width: 100%;
}

.sticky-content {
  background-color: $white !important;
  position: fixed;
  top: 0;
  z-index: 1;
  box-shadow: 0px 12px 17px 0px #0000000d;
}

.font-title-recap {
  background-color: $white;
  font-family: "ducati-extended-bold", Arial;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.004em;
  padding: 24px 20px 24px 20px;
}

.simple-label {
  color: $dark-gray;
}
.simple-label-black {
  color: $smoky-black;
}
.carouselBackground {
  background: transparent;
}

.simple-label,
.simple-label-black {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  margin: 0;
  padding: 0;
}

.simple-bold-label {
  font-family: "ducati-bold";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $smoky-black;
  padding: 0;
  margin: 0;
}

.recap-right-menu-btn {
  width: 100%;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  @media screen and (min-width: $width-large-min) {
    max-width: 300px !important;
  }
}

.display-col-left {
  display: none;
}

.display-col-right {
  display: none;
}

.qtn-hr-container-img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.qtn-hr-content {
  justify-content: center;
}

.qtn-hr-content,
.qtn-hr-content-left,
.qtn-hr-content-right {
  padding: 0;
}

.qtn-hr-content-right {
  background-color: $white;
}

.bike-conf-details-content {
  background: $white;
  padding-left: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 20px !important;
}

.bike-conf-details-content-borders {
  border-top: 1px solid $feature-grey;
  border-bottom: 1px solid $feature-grey;
}

.qtn-hr-navbar {
  background-color: $white;
  padding-top: 16px;
}

.technical-info-accordion {
  border-top: 1px solid $feature-grey;
  margin-left: 20px;
  margin-right: 20px;
}

.technical-info-accordion-button {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
}

@media screen and (min-width: $width-large-min) {
  .font-title-recap {
    background-color: transparent;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.007em;
    padding: 0;
  }
  .qtn-hr-navbar {
    border-top: 1px solid $feature-grey;
    background-color: transparent;
    margin-top: 16px;
  }
  .display-col-left {
    display: block;
  }
  .display-col-right {
    display: block;
  }
  .qtn-hr-content {
    padding-top: 48px;
  }
  .qtn-hr-content-left {
    padding-right: 24px;
  }
  .qtn-hr-content-right {
    padding-left: 64px;
  }
  .qtn-hr-content-right {
    background-color: transparent;
  }
  // STICKY
  .qtn-hr-container-sticky {
    background-color: $white !important;
    position: fixed;
    top: 0;
    z-index: 1;
    box-shadow: 0px 12px 17px 0px #0000000d;
    max-height: 128px;
  }
  .qtn-hr-navbar-sticky {
    background-color: $white !important;
    border-top: none;
    .font-title-recap {
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.005em;
    }
  }
}
