@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.right-side-header-row {
  border-bottom: 1px solid $light-gray !important;
  min-height: 75px;
  display: flex;
  align-items: center;
}

.label-configuration-code {
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $dark-gray;
  padding-top: 19px;
  padding-left: 16px;
  padding-bottom: 20px;
  margin: 0;
}

.configuration-code {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.007em;
  color: $smoky-black;
  padding-top: 19px;
  padding-right: 35px;
  padding-bottom: 20px;
  margin: 0;
}

.btn-add-notes {
  padding-top: 21px !important;
  padding-left: 16px !important;
  padding-bottom: 31px !important;
}

.btn-add-notes,
.btn-edit-notes {
  font-family: "ducati-extended-bold" !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.007em !important;
  text-align: start !important;
  color: $dark-gray !important;
}

.add-notes-form-content {
  padding-left: 15px;
  padding-top: 18px;
  padding-bottom: 27px;
  padding-right: 32px;
}

.add-notes-form-content label {
  font-family: "ducati" !important;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $dark-gray !important;
}

.add-notes-form-content input {
  border: 1px solid $dark-gray;
  border-radius: 4px;
}

.add-notes-left-col {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.add-notes-right-col {
  padding-left: 0 !important;
  padding-right: 32px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.add-notes-form-submit {
  background: $dark-gray !important;
  border-radius: 4px !important;
  font-family: "ducati-extended-bold" !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.007em !important;
}

.add-notes-form-content input::placeholder {
  font-family: "ducati" !important;
  font-size: 16px !important;
  line-height: 26px !important;
  letter-spacing: 0.007em !important;
  color: $feature-grey !important;
}

.notes {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
  color: $smoky-black;
  margin-bottom: 0;
  margin-top: 8px;
}

@media (max-width: 576px) {
  .label-configuration-code {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.008em;
  }
  .configuration-code {
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.008em;
  }
  .add-notes-form-content label {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.008em;
  }
}

@media (max-width: 991px) {
  .add-notes-form-content input {
    height: 95px;
    margin-bottom: 8px;
  }
  .add-notes-form-submit {
    width: 100% !important;
    height: 48px;
  }
  .add-notes-left-col {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 0 !important;
    padding-bottom: 8px !important;
  }
  .add-notes-right-col {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: $width-medium-max) {
  .stickybar {
    margin-top: 191px;
  }
}
@media (min-width: $width-large-min) {
  .right-side-header-container {
    height: 192px;
  }
}
