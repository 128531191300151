@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.div-img {
  padding-right: 1rem;
}

.div-reduce-img {
  margin: 4px;
}

@media (max-width: 500px) {
  .div-reduce-img {
    margin: 0;
  }
}

.img_modal {
  cursor: pointer;
}

.title {
  font-family: "ducati-bold", Arial;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 13px !important;
  color: $black;
  hyphens: auto;
}

.title--scrambler {
  font-family: "scrambler-bold";
  font-size: 18px;
  color: $black;
  hyphens: auto;
}

.content {
  min-height: 150px;
  border: none !important;
}

.radio-button {
  border-radius: 50% !important;
  cursor: pointer;
  height: 37px !important;
  width: 37px !important;
}

.radio-button:focus {
  box-shadow: none !important;
}

.radio-button:checked {
  border-radius: 50% !important;
}

.check-button {
  cursor: pointer;
  height: 37px !important;
  width: 37px !important;
  border: none !important;
}

.check-button:focus {
  box-shadow: none !important;
}

.check-button-apparel-checked,
.check-button:checked {
  background-color: transparent !important;
  border: none !important;
}

.position-checkbox {
  margin-right: 8.5px;
}

.border_configurator {
  border-bottom: 1px solid $light-gray;
}

@media (min-width: $width-large-min) {
  .title {
    font-size: 18px;
  }
  .btn-arrow-right .arrow-right {
    transition: 0.3s ease-in-out;
  }

  .btn-arrow-right:hover .arrow-right {
    transform: translate(30%);
  }
}
.circle {
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: transparent;
  margin-top: 0.25em;
}
.circle-radio-button {
  height: 25px !important;
  width: 25px !important;
}
.circle-check-button {
  margin: 11px 6.5px 6.5px 6.5px;
  height: 25px !important;
  width: 25px !important;
}
.height_card {
  min-height: 150px;
  justify-content: center;
}
.height_element_check,
.height_label {
  align-items: center;
  min-height: 75px;
}

.not-cliccable {
  cursor: default;
}
@media (max-width: $width-large-min) {
  .height_card {
    overflow: hidden;
  }
}

@media (max-width: $width-xsmall-medium) {
  .title {
    font-size: 14px;
  }
}

@media (max-width: $width-xsmall-min) {
  .title {
    font-size: 13px;
  }
}
