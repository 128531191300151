@import "../../../../common/assets/sass/colors.scss";

.cart-status-ul {
  list-style-type: none !important;
  display: flex;
  flex-direction: column;
}

.cart-status-li {
  margin-bottom: 1.5rem;
}
