@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.configurator-content {
  height: calc(100vh - 76px);
}

.height-configurator {
  min-height: 90vh;
  margin-top: 10rem;
  align-content: center;
  background-color: $white;
}

.font-transitor-title {
  font-family: "ducati-extended-bold", Arial;
  font-size: 40px !important;
  line-height: 48px;
  letter-spacing: 0.003em;
  color: $smoky-black;
}

.font-transitor-desc p {
  font-family: "ducati", Arial;
  font-size: 18px !important;
  line-height: 28px;
  letter-spacing: 0.006em;
  background-color: $white;
  color: $smoky-black;
}

.configurator-content {
  background-color: $off-white;
  display: flex;
}

@media (min-width: $width-large-min) {
  .price-info-modal {
    width: 896px !important;
    height: 710px;
  }
  .configurator-content {
    max-height: calc(100vh - 76px);
  }
  .model-information-modal {
    width: 742px !important;
    height: 391px;
  }
  .configurator-container {
    max-height: calc(100vh - 76px);
  }
}

@media (max-width: $width-medium-max) {
  .configurator-content--sticky {
    height: 100vh;
  }

  .configurator-content-container {
    height: 100%;
    overflow-y: scroll;
  }
}

@media (max-width: $width-medium-max) and (orientation: landscape) {
  .configurator-content {
    height: 100vh;
  }
}
