@import "../../../assets/sass/sizing.scss";
@import "../../../assets/sass/button.scss";

.aftermarket-details-modal {
  background: $bg-color;
}

.font-title {
  font-family: "ducati-extended-bold" !important;
  overflow-wrap: break-word;
  font-size: 32px !important;
  line-height: 40px !important;
  letter-spacing: 0.004em !important;
}

.font-price {
  font-family: "ducati-extended-bold" !important;
  font-size: 24px !important;
  display: inline !important;
}

.font-price-total {
  font-family: "ducati-extended-bold" !important;
  font-size: 20px !important;
  display: inline !important;
}

.font-descr-price {
  font-family: "ducati", Arial;
}

.font-title1-modal {
  font-family: "ducati-extended-bold" !important;
  font-size: 18px !important;
}

.font-descr1-modal {
  font-family: "ducati" !important;
  font-size: 14px !important;
}

.font-codice {
  font-family: "ducati" !important;
}

.font-codice-bold {
  font-family: "ducati-bold-extra" !important;
}

.font-codice,
.font-codice-bold {
  font-size: 14px !important;
  text-transform: uppercase;
}

.font-title-card {
  font-family: "ducati-bold" !important;
  font-size: 18px !important;
}

.font-descr {
  font-family: "ducati" !important;
  font-size: 16px !important;
}

.font-1 {
  font-family: "ducati-bold" !important;
  font-size: 16px !important;
}

.button-style,
.button-style-remove {
  width: 100%;
  height: 48px;
}

.margin-button {
  margin-top: 41px;
}

.margin-codice {
  margin-top: 48px;
}

.margin-descr {
  margin-top: 32px;
  margin-bottom: 28px;
}

.margin-button2 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.overflow {
  width: 100%;
  max-height: 100vh;
}

.margin-icon-text {
  margin-top: 7px;
}

.margin-1 {
  margin-top: 24px;
}

.margin-2 {
  margin-top: 28px;
}

.margin-3 {
  margin-top: 8px;
}

.margin-4 {
  margin-top: 37px;
}

.margin-5 {
  margin-top: 12px;
}

.box-black-dettaglio-accessorio {
  margin-top: 32px;
  background: radial-gradient(
        217.76% 802.11% at 146.72% -140%,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0) 84.38%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #0f0f0f;
  /* Card Shadow */

  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  min-width: 423px;
  min-height: 144px;
}

.font-dettaglio-accessorio-1 {
  font-family: "ducati", Arial;
  font-size: 16px !important;
  color: #ffffff !important;
  margin-top: 13px;
}

.font-dettaglio-accessorio-2 {
  font-family: "ducati-extended-bold", Arial;
  font-size: 18px !important;
  color: #ffffff !important;
}

.font-dettaglio-accessorio-3 {
  font-family: "ducati", Arial;
  font-size: 14px !important;
  color: #ffffff !important;
}

.margin-dettaglio {
  margin-left: 16px;
  padding-top: 1px;
  margin-right: 3px;
}

.during-popup {
  opacity: 0.5 !important;
}

.classNameButton {
  background-color: #ebebeb !important;
}

.classNameButton:not(.collapsed) {
  background-color: #ebebeb !important;
}

.select--padding {
  padding: 16px;
}

.margin_right_side {
  margin-left: 83px;
  margin-right: 105px;
}

.aftermarket-information-details-padding {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.visibilty-text {
  margin-left: 15px;
  display: inline;
  font-family: "ducati";
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $smoky-black;
}

.footer {
  background: $white;
  position: fixed;
  bottom: 0;
  left: 0rem;
  z-index: 1;
}

.footerbtn {
  padding: 20px !important;
  background-color: $white !important;
  z-index: 9 !important;
}

.cancel-btn {
  width: 100% !important;
  height: 48px !important;
  margin-top: 1rem;
}

.border {
  border-top: 1px solid $feature-grey;
}

@media (min-width: $width-large-min) {
  .overflow {
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    scrollbar-width: none;
  }
  .js .overflow::-webkit-scrollbar {
    display: none !important;
  }

  .incomaptibility-modal {
    width: 1006px !important;
  }
  .aftermarket-information-details-padding {
    padding-left: 83px !important;
    padding-right: 106px !important;
  }
}

@media (max-width: $width-medium-max) {
  .aftermarket-details-modal {
    height: 100%;
  }
  .incomaptibility-modal--ignore-padding {
    margin-left: -12px !important;
  }
  .aftermarket-information-details-padding {
    padding-bottom: 152px !important;
  }
  .footer {
    background: transparent !important;
  }
}

.icon-size{
  width: 32px;
  height: 32px;
}

.header-close-btn {
  border: none !important;
  padding-top: 0.6rem !important;
  padding-left: 0rem !important;
}

.header-close-btn:hover {
  opacity: 0.6;
}

.header-close-btn-first {
  border: none !important;
  padding-top: 0.6rem !important;
  padding-left: 0rem !important;
  opacity: 0.6;
}

