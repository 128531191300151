@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";
@import "../../../common/assets/sass/button.scss";

.rate-modal-title,
.rate-modal-text,
.rate-button-container {
  text-align: center;
}

.rate-modal-container {
  margin-top: 20px;
  padding: 0 56px 0 72px;
  text-align: initial;
}

.rate-modal-title {
  margin-bottom: 24px;
  font-family: "ducati-extended-bold", Arial;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.003em;
}

.rate-modal-text {
  margin-bottom: 40px;
  font-family: "ducati", Arial;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.rate-button-container {
  padding-top: 48px;
  padding-bottom: 48px;
}

.button-style {
  width: 255px;
  height: 48px;
  padding: 12px, 16px, 12px, 16px;
}

@media (max-width: $width-medium-max) {
  .rate-modal-container {
    padding: 0 8px;
    margin: 0px 25px;
  }
  .rate-modal-title {
    font-size: 20px;
    margin-bottom: 11px;
  }

  .rate-modal-text {
    margin-bottom: 32px;
  }
}
