@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.Quantity-selector__title {
  font-family: var(--font-regular);
  font-size: 18px;
  line-height: 27px;
  color: $smoky-black;
  text-transform: capitalize;
}

.Quantity-selector__select {
  width: 124px;
  height: 56px;
}

.quantity-selector-cart-select {
  width: 96px;
  height: 40px;
  font-family: var(--font-bold-extra);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.007em;
  color: $smoky-black;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
}

.Quantity-selector__select,
.quantity-selector-cart-select {
  border-radius: 4px;
  background: url("../../assets/images/IconModal/select_arrow_down_black.svg")
    right;
  background-repeat: no-repeat;
  background-origin: content-box;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-right: 18px;
  border: 0;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
}

@media (min-width: $width-large-min) {
  .Quantity-selector__select,
  .quantity-selector-cart-select {
    border: 1px solid $feature-grey;
    box-shadow: none;
  }
}
@media (max-width: $width-medium-max) {
  .Quantity-selector__title {
    margin: 0 !important;
    padding: 0 !important;
  }
}
