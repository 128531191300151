@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.recap-form-btn-confirm,
.recap-form-btn-cancel {
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  border-radius: 4px;
  font-family: "ducati-extended-bold";
  line-height: 24px;
  letter-spacing: 0.007em;
}

.recap-form-btn-confirm {
  background-color: $dark-gray;
  color: $white;
}

.recap-form-btn-cancel {
  border: 1px solid $smoky-black;
  color: $smoky-black;
}

.recap-form-voice-title,
.recap-form-voice-label {
  font-family: "ducati";
  color: $smoky-black;
}

.recap-form-voice-title,
.recap-form-voice-title-bold {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
}

.recap-form-voice-title-bold {
  font-family: "ducati-bold";
  color: $smoky-black;
}

.recap-form-add-btn-label {
  font-family: "ducati-extended-bold";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.007em;
  text-decoration-line: underline;
  color: $dark-gray;
}

.recap-form-voice-label {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.recap-form-voice-input {
  height: 62px;
  padding: 18px 15px;
  border: 1px solid $dark-gray;
  border-radius: 4px;
  background-color: transparent;
  font-family: "ducati";
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $smoky-black;
  outline: 0;
}

.recap-form-voice-input::-webkit-input-placeholder {
  color: $feature-grey !important;
}

.custom-selector {
  background-color: transparent !important;
  background-image: url("../../../common/assets/images/ArrowDownDarkGray.svg");
  border: 1px solid $dark-gray !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  height: 62px !important;
  width: 100%;
}

.custom-selector option {
  font-family: "ducati" !important;
  font-size: 16px;
  line-height: 136%;
  color: $smoky-black;
}

.recap-form-label {
  hyphens: auto;
}

input:invalid {
  color: $ducati-red;
}

@media (min-width: $width-large-min) {
  .recap-form-btn-confirm,
  .recap-form-btn-cancel {
    width: 114px;
  }
  .recap-form-label {
    hyphens: none;
  }
}
