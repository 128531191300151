@import "../../../assets/sass/colors.scss";
@import "../../../assets/sass/sizing.scss";

.div-img {
  margin: 15px;
}

.div-reduce-img {
  margin: 4px;
}

.dimensioni-img {
  width: 100px;
  height: 100px;
}

@media (max-width: 500px) {
  .div-reduce-img {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .title {
    font-size: 16px !important;
  }
}

.title {
  font-family: var(--font-bold);
  margin-bottom: 0;
  line-height: 24px;
  letter-spacing: 0.007em;
  color: $smoky-black;
}

.price {
  font-family: var(--font-bold-extra);
  font-size: 14px !important;
}

.content {
  max-width: 458px;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
  border-right: 1.12121px solid #ebebeb !important;
  align-content: center !important;
  border-radius: 2px !important;
  height: 110px !important;
  max-height: 100px;
  min-height: 110px;
}

.position-checkbox {
  margin-right: 8.5px;
}

.max-heigth {
  max-height: 110px !important;
}

.font1 {
  font-family: var(--font-regular);
  font-size: 14px;
  margin-bottom: 0;
  color: $smoky-black;
}

.img {
  width: 21px;
  height: 13px;
  border-radius: 2px;
}

.border-right-color-card-red {
  border-right: 3px solid #e73333 !important;
}

.border-right-color-card-green {
  border-right: 3px solid #197a34 !important;
}

.img-container {
  border-right: 1.2px solid $light-gray;
}

.card-margin {
  margin-top: 2px;
}

.card-text-container {
  padding-left: 6px !important;
}

.tick-img {
  width: 15px;
  margin-top: 9px;
  margin-right: 18px;
}

.compatibility-card-img {
  width: auto !important;
  max-width: 100% !important;
}

@media (max-width: $width-medium-max) {
  .card-margin {
    margin-top: 8px;
  }

  .content {
    max-width: 100%;
  }
}
