@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";
@import "../../../common/assets/sass/button.scss";

.textuppercase {
  text-transform: uppercase !important;
}

.language-button {
  padding: 8px 12px !important;
  font-family: "ducati-extended-bold" !important;
  font-size: 14px !important;
  color: $smoky-black;
  line-height: 24px !important;
  letter-spacing: 0.007em;
  text-transform: capitalize;
}

.language-header-title {
  font-family: "ducati-extended-bold" !important;
  text-transform: initial;
  font-size: 18px;
  color: $smoky-black;
  line-height: 28px;
  letter-spacing: 0.007em;
}

.modal-close {
  margin-left: 14px;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: none;
  border-radius: 100px;
}

.modal-close:hover {
  opacity: 0.6;
}

.international-btn {
  font-family: "ducati" !important;
  line-height: 26px !important;
  letter-spacing: 0.007em !important;
  color: $smoky-black !important;
}

.international-btn:focus {
  outline: 1px solid $black !important;
}

.button-style {
  width: 100%;
  height: 56px;
  max-width: 302px;
}

.modal-close-title {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: #000000;
}

.modal-close-text {
  font-family: "ducati";
  line-height: 26px;
  letter-spacing: 0.007em;
  color: #000000;
}

@media (min-width: $width-large-min) {
  .language-header-title {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.005em;
  }

  .modal-close {
    margin: 22.5px 0 14.5px 0;
  }

  .confiramtion-modal {
    width: 742px !important;
    height: 391px;
  }
}
