@import "../../../assets/sass/sizing.scss";
@import "../../../assets/sass/button.scss";

.smart-accessory-selection-modal-information,
.smart-accessory-selection-modal-information--scrambler,
.smart-accessory-selection-modal-configuration,
.smart-accessory-selection-modal-configuration--scrambler,
.smart-accessory-selection-modal-action {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.smart-accessory-selection-modal-information,
.smart-accessory-selection-modal-information--scrambler {
  padding-top: 34px;
  padding-bottom: 41px;
}

.smart-accessory-selection-modal-information {
  background-color: $off-white;
}

.smart-accessory-selection-modal-information--scrambler {
  background-color: $light-gray;
}

.smart-accessory-selection-modal-configuration,
.smart-accessory-selection-modal-action {
  background-color: $white;
}

.smart-accessory-selection-modal-action {
  flex-direction: column-reverse;
}

.smart-accessory-selection-modal-title,
.smart-accessory-selection-modal-description {
  font-family: var(--font-extended-bold) !important;
}

.cancel-btn {
  width: 100% !important;
  height: 56px !important;
  padding: 0 !important;
  margin-bottom: 44px !important;
}

.smart-accessory-selection-modal-title {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
  padding: 0 !important;
}

.smart-accessory-selection-modal-description {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  padding: 0 !important;
  margin-bottom: 41px !important;
}

.smart-accessory-selection-modal-title,
.smart-accessory-selection-modal-description,
.smart-accessory-selection-modal-minicard-title,
.smart-accessory-selection-modal-minicard-description,
.smart-accessory-selection-modal-minicard-price {
  color: $smoky-black !important;
}

.smart-accessory-selection-modal-configuration--scrambler {
  background-color: $light-gray;
}

.smart-accessory-selection-modal-minicard {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
}

.conflict-minicard-container {
  max-width: 458px;
}

.smart-accessory-selection-modal-minicard-left {
  border-right: 1.2px solid $light-gray;
}

.reduce-image {
  width: 96px !important;
  height: 96px !important;
}

.smart-accessory-selection-modal-minicard-title {
  font-family: var(--font-bold) !important;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
  margin: 0 !important;
}

.smart-accessory-selection-modal-minicard-description {
  font-family: var(--font-regular) !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.smart-accessory-selection-modal-minicard-price {
  font-family: var(--font-bold-extra) !important;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin: 0 !important;
}

.smart-accessory-selection-modal-selectors {
  flex-direction: column;
}

.smart-accessory-selection-modal-size {
  margin-bottom: 24px !important;
}

.border-right-color-card-red {
  border-right: 3px solid #e73333 !important;
}

.border-right-color-card-green {
  border-right: 3px solid #197a34 !important;
}

.tick-img {
  width: 15px;
  margin-top: 9px;
  margin-right: 18px;
}

.img {
  width: 21px;
  height: 13px;
  border-radius: 2px;
}

.font1 {
  font-family: var(--font-regular);
  font-size: 14px;
  margin-bottom: 0;
  color: $smoky-black;
}

.modal-header--scrambler {
  background-color: $light-gray;
}

@media (min-width: $width-large-min) {
  .smart-accessory-selection-modal-description {
    font-family: var(--font-bold) !important;
    letter-spacing: 0.006em;
  }
  .smart-accessory-selection-modal-minicard-title {
    line-height: 24px;
  }
  .smart-accessory-selection-modal-minicard-price {
    font-size: 16px;
  }
  .smart-accessory-selection-modal-action {
    flex-direction: row;
    border-top: 1px solid $feature-grey;
  }

  .smart-accessory-selection-modal-action--scrambler {
    border: none;
  }

  .smart-accessory-selection-modal-information,
  .smart-accessory-selection-modal-information--scrambler {
    padding-top: 0 !important;
  }
  .smart-accessory-selection-modal-information,
  .smart-accessory-selection-modal-information--scrambler,
  .smart-accessory-selection-modal-configuration,
  .smart-accessory-selection-modal-configuration--scrambler,
  .smart-accessory-selection-modal-action {
    width: 594px !important;
    padding-left: 67px !important;
    padding-right: 67px !important;
  }
  .cancel-btn {
    width: 220px !important;
    margin-top: 44px !important;
  }
  .smart-accessory-selection-modal-selectors {
    flex-direction: row;
  }
  .smart-accessory-selection-modal-size {
    margin-bottom: 0 !important;
  }
}

.btn-close-modal img:hover {
  opacity: 0.6;
}

.btn-close-modal--scrambler {
  width: 15px;
  height: auto;
}
