@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.omologations-conatiner {
  margin-top: 36px;
  padding-top: 24px;
  padding-bottom: 9px;
  border-top: 1px solid $feature-grey;
}

.omologations-conatiner__omologation {
  display: flex;
}

.omologations-conatiner__title,
.omologations-conatiner__omologation-description {
  font-family: var(--font-regular);
  line-height: 24px;
}

.omologations-conatiner__title {
  margin-bottom: 28px;
  color: $smoky-black;
}

.omologations-conatiner__omologation-description {
  margin-bottom: 12px;
  margin-left: 20px;
  color: $dark-gray;
}

.omologations-conatiner__omologation-img {
  width: auto;
  min-width: 36px;
  max-width: 72px;
  margin-top: 8px;
}
@media (max-width: $width-medium-max) {
  .omologations-conatiner__omologation-description {
    color: $smoky-black;
  }
}
