@import "../../../../assets/sass/colors.scss";
@import "../../../../assets/sass/sizing.scss";

.carousel-configurator-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: $off-white;
  height: inherit;
  margin-bottom: 20px;
}

.image_moto {
  filter: blur(0);
  transition: filter 0.5s ease-in;
}

.image_moto_loading {
  filter: blur(5px);
}

.loading-progress {
  position: absolute;
  width: 1%;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.carousel-configurator-container,
.carousel-configurator-content,
.carousel-configurator-menu,
.carousel-configurator-img,
.carousel-configurator-footer {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.carousel-configurator-content {
  display: flex;
}

.carousel-configurator-menu {
  display: flex;
  align-items: center;
}

.carousel-configurator-footer {
  display: flex;
  padding-bottom: 25px;
  position: absolute;
  bottom: 0;
}

.cursor {
  cursor: url("../../../../assets/images/zoom/cursorZoom.svg"), pointer;
}

.size {
  width: 100% !important;
  height: 100% !important;
}

.button-close {
  position: absolute;
  z-index: 2;
  max-width: calc(100% - 20px);
}

.key-panel {
  position: absolute;
  z-index: 2;
  max-width: calc(100% - 20px);
}

.view-menu {
  // background-color: $off-white !important;
  width: auto !important;
  border-right: none !important;
  padding-left: 100px;
  background: rgba(255, 255, 255, 0.9);
}

.d-content {
  display: contents;
}

.margin-top-img {
  margin-top: 12px;
  cursor: pointer;
}

.border-img {
  border: 1.5px solid #000000;
  border-radius: 5px;
}

.gallery-mode {
  background: rgba(255, 255, 255, 0.9);
}

@media (max-width: $width-medium-max) {
  .positionRotation {
    right: 30px;
  }
  .arrow-left {
    left: 30px;
  }
  .arrow-right {
    right: 30px;
  }
}

.img-size-offcanvas {
  height: 95px;
  width: 158px;
}

@media (min-width: 350px) {
  .img-size-offcanvas {
    height: 35px;
    width: 78px;
  }
}

@media (min-width: 500px) {
  .img-size-offcanvas {
    height: 45px;
    width: 108px;
  }
}

@media (min-width: 650px) {
  .img-size-offcanvas {
    height: 65px;
    width: 128px;
  }
}

@media (max-width: $width-medium-max) {
  .carousel-configurator-footer {
    padding-bottom: 25px;
    position: absolute;
    bottom: 0;
  }
}
.img_portrait {
  width: 90% !important;
}
