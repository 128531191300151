@import "../../assets/sass/colors.scss";

.logotitle {
  font-family: "ducati" !important;
  font-size: 16px !important;
}
.logobackground {
  background-color: $off-white;
}
.logoprogressbar {
  width: 80% !important;
  max-width: 400px !important;
}
