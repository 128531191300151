@import "../../../common/assets/sass/button.scss";
@import "../../../common/assets/sass/sizing.scss";

.qtn-status-bar-container {
  background-color: $smoky-black;
  color: $white;
  height: 60px;
}

.qtn-status-bar-sticky,
.qtn-status-bar {
  z-index: 3;
}

.qtn-status-bar-sticky {
  position: fixed;
  bottom: 0;
}
.qtn-status-bar {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.font-price-bold {
  font-family: "ducati-extended-bold";
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0px;
}

.qtn-status-widget-container {
  width: 309px;
}

.qtn-status-widget-container-sticky {
  position: fixed;
  top: 140px;
}

.qtn-status-widget-container,
.qtn-status-widget-container-mobile {
  background-color: $bg-color;
}

.qtn-status-widget-container-mobile {
  height: calc(100vh - 60px);
}

.qtn-status-widget-top {
  height: auto;
}

.qtn-status-widget-top,
.widget-discount-variation-light {
  background-color: $white;
  color: $smoky-black;
}

.qtn-status-widget-bottom {
  height: 100%;
}

.qtn-status-widget-bottom,
.widget-discount-variation-dark {
  background-color: $smoky-black;
  color: $white;
}

.line {
  background-color: $feature-grey;
  width: 2px;
  height: 0.5rem;
}

.title {
  font-family: "ducati-extended-bold";
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
}

.subtitle {
  font-family: "ducati-bold";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
}

.description {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.price {
  font-family: "ducati-extended-bold";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.006em;
}

.description-light-gray {
  color: $light-gray;
}

.dashed-light {
  border-top: 1px dashed $light-gray;
}

.dashed-dark {
  border-top: 1px dashed $light-gray;
}

@media (min-width: $width-large-min) {
  .qtn-status-widget-container {
    max-width: 310px;
  }
  .line {
    height: 1rem;
  }
}
