@import "../../../../common/assets/sass/button.scss";
@import "../../../../common/assets/sass/sizing.scss";

.qtn-bd-container {
  background: $off-white;
}

.qtn-bd-content-left {
  padding: 24px 20px 0 20px;
}

.button-save {
  font-family: "ducati-condensed-bold-extra";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  height: 50px;
  padding: 0 52px 0 52px;
}

.border-top {
  border-top: 1px solid $feature-grey;
}

.save-myducati-modal-container {
  padding-left: 83px !important;
  padding-right: 83px !important;
  padding-bottom: 86px !important;
}

.save-myducati-modal-title {
  font-family: "ducati-extended-bold", Arial;
  letter-spacing: 0.007em;
  font-size: 18px;
  line-height: 28px;
  color: $black;
}

.save-myducati-modal-desc {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $black;
}

.save-myducati-modal-btn-container {
  display: flex;
  justify-content: center;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0 !important;
}

.btn-dimension {
  width: 478px;
  height: 56px;
}

.qtn-bd-content-right {
  display: flex;
  flex-direction: column;
}

@media (max-width: $width-medium-max) {
  .incomaptibility-modal--ignore-padding {
    margin-left: -12px !important;
  }
  .qtn-bd-content-right-sticky {
    position: fixed;
    bottom: 0;
    z-index: 1;
  }
}

@media (min-width: $width-large-min) {
  .qtn-bd-container {
    background: transparent;
  }
  .qtn-bd-content-left {
    width: 733px;
    padding: 0 24px 0 0;
  }
  .price-info-modal-body {
    width: 896px !important;
    height: 627px;
  }
  .qtn-bd-content-right {
    width: 310px;
  }
  .button-save {
    padding: 12px 52px 12px 52px;
  }
  .model-information-modal {
    width: 742px !important;
    height: 391px;
  }
}
