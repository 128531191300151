@import "../../common/assets/sass/sizing.scss";
@import "../../common/assets/sass/colors.scss";

.initial {
  background-color: #f2f2f2;
  max-width: 100%;
  overflow-x: hidden;
}

.bodyContent {
  min-height: $min-height-90;
  overflow: hidden;
  background-color: $off-white;
}

.skip-link-font {
  background-color: black;
  color: #bbbcbc;
  height: 28px;
  padding-left: 20px;
}

.skip-link {
  color: white;
  background-color: black;
  margin-right: 1rem;
  position: absolute;
  transform: translateX(-200%);
  transition: transform 0.3s;
}

.skip-link:focus {
  position: static;
  transform: translateX(0);
}
