@import "../../../../../assets/sass/sizing.scss";
@import "../../../../../assets/sass/button.scss";

.remove-highlighted-modal-container {
  width: 1006px !important;
}

.remove-highlighted-modal-content {
  width: 1006px !important;
}

.remove-modal-left,
.remove-accessory-left {
  padding-top: 60px !important;
  padding-left: 63px !important;
}

.remove-modal-left-title {
  font-family: "ducati-extended-bold" !important;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
}

.remove-modal-left-desc {
  font-family: "ducati-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
}

.remove-modal-left-title,
.remove-modal-left-desc,
.remove-modal-right-title {
  color: $smoky-black;
}

.remove-modal-right-title {
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.remove-modal-left,
.remove-accessory-left .remove-action-buttons {
  background: $off-white;
}

.remove-action-buttons {
  height: auto !important;
  position: sticky;
}

.button-style {
  height: auto !important;
  width: auto !important;
}

.cancel-btn {
  width: 220px !important;
  height: 56px;
  letter-spacing: 0.007em;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cancel-btn-conflict {
  width: 180px !important;
  height: 46px;
  letter-spacing: 0.007em;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.check-box-list {
  height: 83%;
  overflow-y: scroll;
}

.check-box-list::-webkit-scrollbar {
  width: 3px;
}

/* Stile del cursore della scrollbar */
.check-box-list::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.check-box-ul {
  display: flex !important;
  flex-direction: column;
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.card-checker {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 55px;
  background: $off-white;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: $feature-grey;
  border-radius: 4px 0px 0px 4px;
}

.card-checker-input {
  cursor: pointer;
  background-image: url("../../../../../assets/images/IconModal/remove_apparel_not_checked.svg") !important;
}

.card-checker-input-checked {
  background-image: url("../../../../../assets/images/IconModal/remove_apparel_checked.svg") !important;
}

.card-checker-input,
.card-checker-input-checked {
  height: 26px !important;
  width: 26px !important;
  border: none !important;
  background-color: transparent !important;
}

.card-apparel,
.card-accessory {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 458px;
  height: 140px;
  background: $white;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.reduce-image {
  width: 96px !important;
  height: 96px !important;
}

.card-apparel-title,
.card-apparel-desc,
.card-apparel-price {
  color: $smoky-black !important;
  padding: 0 !important;
  margin: 0 !important;
}

.card-apparel-title,
.card-apparel-price {
  font-family: "ducati-bold" !important;
}

.card-apparel-title {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
  hyphens: auto;
}

.card-apparel-desc {
  font-family: "ducati" !important;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.card-apparel-price {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.007em;
}

.reduce-image-container {
  display: flex;
  align-items: center;
}

.btn-close-modal img:hover {
  opacity: 0.6;
}

@media (min-width: $width-large-min) {
  .remove-modal-left,
  .remove-modal-right,
  .remove-modal-right-content {
    height: 611px !important;
  }

  .remove-accessory-left,
  .remove-accessory-right {
    height: 360px !important;
  }

  .remove-modal-right,
  .remove-accessory-right {
    background: $white;
    width: 603px !important;
  }

  .remove-modal-left,
  .remove-accessory-left {
    width: 403px !important;
  }

  .remove-action-buttons {
    border-top: 1px solid #bbbcbc;
  }
}

@media (max-width: $width-medium-max) {
  .remove-highlighted-modal-content {
    display: block !important;
  }

  .remove-modal-left,
  .remove-accessory-left {
    padding: 64px 20px 0 20px !important;
  }

  .remove-highlighted-modal-container {
    width: 100% !important;
  }

  .remove-highlighted-modal-content {
    width: 100% !important;
  }

  .remove-modal-left-title {
    margin: 26px 0 24px 0;
  }

  .remove-modal-left-desc {
    letter-spacing: 0.007em;
  }

  .remove-modal-left-desc {
    font-family: "ducati-extended-bold";
    margin-bottom: 24px;
  }

  .card-apparel {
    width: calc(100% - 55px);
    height: auto;
    min-height: 138px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  }

  .reduce-image-container {
    border-right: 1.5px solid $light-gray;
  }

  .card-apparel-desc-container {
    width: calc(100% - 96px);
    padding: 8px 10px 8px 6px;
  }

  .card-apparel-title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.007em;
  }

  .card-apparel-desc {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.008em;
  }
  .card-apparel-price {
    margin-top: 16px !important;
    font-family: "ducati-bold-extra";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
  }

  .remove-action-buttons {
    max-width: 100vw;
    flex-direction: column;
    margin: 0 !important;
  }

  .cancel-btn {
    height: 48px;
    width: calc(100% - 40px) !important;
    margin: 16px 20px 0 20px;
    letter-spacing: 0.005em;
  }
}
