@import "./colors.scss";

.scrollbox {
  overflow: auto;
  scrollbar-color: $ducati-red transparent;
  scrollbar-width: thin;
  scrollbar-gutter: stable both-edges;
}

.scrollbox::-webkit-scrollbar {
  width: 3px;
}

.scrollbox::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}
