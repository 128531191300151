@import "../../../../../assets/sass/scrollbox.scss";
@import "../../../../../assets/sass/sizing.scss";

.gallery-container {
  background: $bg-color !important;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  scrollbar-width: none;
}

.gallery-container::-webkit-scrollbar {
  width: 0px;
}

.apparels-gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.apparels-gallery-content {
  height: inherit !important;
  background: linear-gradient(180deg, $light-gray 1%, $bg-color 3%);
  height: 100%;
}

@media (min-width: $width-large-min) {
  .apparels-gallery-content {
    background: transparent;
    height: calc(100vh - 306px);
  }
  .apparels-gallery-container {
    height: calc(100vh - 81px);
  }
}
