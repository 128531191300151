@import "../../../assets/sass/button.scss";
@import "../../../assets/sass/sizing.scss";

.content,
.body-content,
.cmb-content {
  width: 100%;
}

.cmb-content-left {
  background-color: #fafafa;
  width: 100%;
}

.cmb-footer {
  width: 100%;
  border-top: none;
}

.button-style {
  width: 100%;
  height: 56px;
}

.title,
.desc,
.card-text,
.card-price {
  color: $smoky-black;
}

.title {
  font-family: "ducati-extended-bold";
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.005em;
  margin-bottom: 9px;
}

.title::first-letter {
  text-transform: capitalize;
}

.desc {
  font-family: "ducati-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.006em;
  margin-bottom: 47px;
}

.title,
.desc {
  padding: 0 1rem 0 1rem;
}

.card-container {
  height: 322px;
}

.card {
  width: auto;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
}

.card-img {
  width: 216px;
  height: 216px;
}

.card-body {
  height: auto;
  padding: 13px 13px 4px 13px !important;
}

.margin6 {
  margin-left: 35px;
}

.btn-close-modal img:hover {
  opacity: 0.6;
}

.conflict-option-list-title {
  color: $smoky-black;
  font-family: "ducati" !important;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  margin-bottom: 16px;
}

.conflict-overflow {
  height: 610px;
  overflow-y: auto;
  overflow-x: hidden;
}
.conflict-overflow::-webkit-scrollbar {
  width: 3px;
  padding-left: 3rem;
}
.conflict-overflow::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.col-radio-button-choise {
  background: $off-white;
  border-style: solid;
  border-color: $feature-grey;
  height: auto;
  border-width: 1px 1px 0px 1px;
  border-radius: 4px 4px 0px 0px;
  padding: 0 12px 0 15px;
}

.radio-button-choise {
  border: 1px solid #0f0f0f !important;
  width: 24px !important;
  height: 24px !important;
}

.radio-button-choise:checked {
  background-image: url("../../../assets/images/ModalIncompatibility/pointRadioModalIncompatibility.svg") !important;
  background-size: 10px 10px;
  background-color: transparent !important;
}

.radio-button-choise:focus {
  box-shadow: none !important;
}

.choice_label {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: $black;
}

.cards-container {
  width: 458px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07) !important;
}

.choices-cart-content {
  border-bottom: 1.2px solid $light-gray;
}

.col-radio-button-choise {
  background: #fafafa;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #bbbcbc;
  border-radius: 4px 0px 0px 4px;
}

.radio-button-choise {
  border: 1px solid #0f0f0f !important;
  width: 24px !important;
  height: 24px !important;
}

.radio-button-choise:checked {
  background-image: url("../../../assets/images/ModalIncompatibility/pointRadioModalIncompatibility.svg") !important;
  background-size: 10px 10px;
  background-color: transparent !important;
}

.radio-button-choise:focus {
  box-shadow: none !important;
}

@media (max-width: $width-large-min) {
  .cmb-content-left {
    background-color: transparent;
  }
  .card-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: $width-large-min) {
  .content,
  .body-content,
  .cmb-content,
  .cmb-footer {
    width: 1006px !important;
  }
  .cmb-content-left {
    width: 403px !important;
    padding: 60px 25px 60px 63px;
  }
  .cmb-content-right {
    padding-left: 63px;
    width: 603px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cmb-content-righ::-webkit-scrollbar {
    width: 3px;
    padding-left: 3rem;
  }
  .cmb-content-righ::-webkit-scrollbar-thumb {
    background-color: $ducati-red;
    border-radius: 4px;
  }
  .cmb-footer {
    height: 110px;
    border-top: 1px solid $feature-grey;
  }
  .title,
  .desc {
    padding: 0;
  }
  .card {
    width: 217px;
  }
  .col-radio-button-choise {
    padding: 0 12px 0 15px;
    border-width: 1px 0px 1px 1px;
    border-radius: 4px 0px 0px 4px;
  }
}
