@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";
@import "../../../../../../assets/sass/button.scss";

.apparel-details-btn-add {
  height: 48px;
}

.confirm-btn {
  height: 56px !important;
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.apparel-details-btn-add,
.confirm-btn {
  width: 100% !important;
}

@media (min-width: $width-large-min) {
  .confirm-btn {
    width: 220px !important;
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
}
