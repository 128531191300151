@import "../../../common/assets/sass/sizing.scss";
@import "../../../common/assets/sass/button.scss";
@import "../discount-section/DiscountSection.module.scss";

.font-card,
.font-subtitle,
.font-price,
.font-price-lable-bold,
.font-price-bold {
  color: $smoky-black;
}

.font-card {
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
}

.font-subtitle {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.font-price-lable,
.font-price {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.font-price-lable {
  font-family: "ducati";
}

.font-price {
  font-family: "ducati-bold";
}

.font-price-lable-bold,
.font-price-bold {
  font-family: "ducati-extended-bold";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

@media (min-width: $width-large-min) {
  .font-card {
    font-family: "ducati-extended-bold";
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.007em;
  }
  .font-price-lable-bold,
  .font-price-bold {
    font-family: "ducati-bold";
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.005em;
  }
  .font-price-lable,
  .font-price {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.006em;
  }
}
