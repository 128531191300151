@import "../../../assets/sass/colors.scss";
@import "../../../assets/sass/sizing.scss";

.gallery-card {
  box-shadow: 0 0 0 transparent;
}

.gallery-card-container {
  background: $white;
}

.gallery-card-brand {
  height: 24px !important;
}

.gallery-card-top {
  border-bottom: 1px solid $light-gray;
}

.gallery-card-title {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: $smoky-black;
  margin-top: 17px;
  margin-bottom: 4px;
}

.gallery-card-description,
.gallery-card-tags {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  margin-bottom: 4px;
}

.gallery-card-description {
  color: $smoky-black;
}

.gallery-card-tags {
  color: $dark-gray;
}

.gallery-card-price {
  display: flex;
  justify-content: center;
  background: $white;
  font-family: "ducati-extended-bold";
  font-size: 16px !important;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: $smoky-black;
  padding-top: 1rem;
  padding-bottom: 21px;
}

.gallery-card-colors-ul {
  display: flex !important;
  justify-content: center;
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.gallery-card-colors-li {
  margin-right: 22px;
}

.color-list-content li:last-child {
  margin: 0;
}

.color-list-content {
  background: $white;
  height: 0;
  overflow: hidden;
  display: grid;
}

.gallery-card-button {
  margin: 0;
  padding: 0;
}

.apparel-color-img {
  height: 20px !important;
  width: 20px !important;
}
