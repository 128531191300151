@import "../../assets/sass/sizing.scss";

.image_moto {
  object-fit: cover;
  filter: blur(0);
  transition: filter 0.5s ease-in, transform 0.5s linear;
}

.image_moto_loading {
  filter: blur(5px);
}

@media (max-height: 650px) and (orientation: landscape) {
  .image_suspance {
    height: 100vh !important;
  }
}

@media (max-height: 650px) {
  .image_moto {
    width: 75%;
  }
}

.unsetSize {
  width: 158px !important;
  height: 95px !important;
  min-height: unset !important;
}

@media (max-width: $width-medium-max) {
  .image_moto {
    min-height: 220px !important;
    width: 100% !important;
  }

  .unsetSize {
    width: 75px !important;
    height: 45px !important;
    min-height: unset !important;
  }
}

@media (min-width: $width-large-min) {
  .shift-left-img {
    transform: translateX(-200px);
  }

  .shift-right-img {
    transform: translateX(0px);
  }
}

.loading-progress {
  position: absolute;
  width: 1%;
  left: 43%;
  top: 50%;
  z-index: 1;
}
