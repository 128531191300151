@import "../../../assets/sass/button.scss";
@import "../../../assets/sass/sizing.scss";

.minicard {
  background: $white;
}

.imgBorder {
  border-right: 1.5px solid $light-gray;
}
