.button-close {
  position: absolute;
  z-index: 2;
  max-width: calc(100% - 20px);
}

.cursor-change {
  cursor: e-resize;
  cursor: url("../../assets/images/cursor360.svg"), pointer;
}

.turntable {
  height: 100vh;
  width: 100vw;
}
