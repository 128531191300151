@import "../../assets/sass/colors.scss";

.model-information-container {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding-right: 40px;
}

.model-information__btn {
  width: 220px;
  height: 39px;
  top: 0;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border: none;
  border-radius: 21px;
}

.model-information__btn__text {
  margin: 0;
  display: inline;
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.model-information__btn__icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@media (min-width: 992px) {
  .model-information-modal {
    width: 896px !important;
  }
}
