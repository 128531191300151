@import "../../../assets/sass/colors.scss";
@import "../../../assets/sass/button.scss";
@import "../../../assets/sass/sizing.scss";

.font_label_menu,
.font-label-menu--scrambler {
  color: $smoky-black;
  font-family: "ducati-condensed" !important;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.007em;
}

.font-label-menu--scrambler {
  font-family: "scrambler-bold" !important;
}

.all_font {
  color: $black;
  font-family: "ducati-condensed-bold" !important;
  font-size: 16px;
  line-height: 20px;
}

.margin_header {
  margin-top: 31px;
}

.margin_body {
  margin-left: 49px;
  -ms-overflow-style: none;
}

.margin_body::-webkit-scrollbar {
  display: none;
}

.padding_span {
  padding-left: 30px;
}

.button_x {
  height: auto;
  opacity: 1 !important;
  margin-right: 15px !important;
}

.button_x:checked {
  border-color: transparent !important;
}

.font1 {
  font-family: "ducati-condensed-bold";
  font-size: 16px;
  line-height: 20px;
  color: $dark-gray;
}

.font2 {
  font-family: "ducati-condensed-bold";
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.font3 {
  font-family: "ducati-condensed-bold";
  font-size: 16px;
  line-height: 22px;
  color: $dark-gray;
}

.font4 {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.007em;
}

.font5 {
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  letter-spacing: 0.008em;
}

.border_top1 {
  margin-right: 44px;
  margin-top: 33px;
  border-top: 1px solid grey;
}

.margin_top2 {
  margin-top: 31px;
}

.margin_top3 {
  margin-top: 24px;
}

.margin_top4 {
  margin-top: 64px;
}

.margin_top5 {
  margin-top: 15px;
}

.margin5 {
  margin-left: 14px;
}

.cursor {
  cursor: pointer;
}

.borderSelected {
  border: 1px solid black;
  border-radius: 20px;
}

.margin_bottom {
  margin-bottom: 35px;
}

.text_truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.text_trasform {
  text-transform: capitalize;
}

.button-style-1 {
  height: 56px;
  width: 290px !important;
  margin-right: 16px;
}

.button-style-2 {
  height: 56px;
  width: 290px !important;
}

.margin_bottom2 {
  margin-bottom: 90px;
}

.padding-border {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
  padding-left: 16px;
}

.text {
  white-space: normal;
}

.offcanvas-btn:focus-visible,
.change-country-btn-outline {
  outline: 1px solid $black !important;
}

@media (max-width: $width-medium-max) {
  .font2,
  .font1 {
    font-family: "ducati-condensed";
  }
  .margin_top3 {
    margin-top: 20px;
  }
  .modalChangeConfiguration {
    flex-flow: column-reverse;
    align-items: center;
  }
  .marginButtonModal {
    margin: 0 !important;
  }
  .marginButtonModalfirst {
    margin-bottom: 16px !important;
  }
  .margin_modal_mobile {
    margin-bottom: 37px !important;
  }
  .breakspaces {
    white-space: pre-wrap;
  }
}

.logged_border {
  border: 1px solid $ducati-red;
  border-radius: 60px;
}
