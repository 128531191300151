@import "../../assets/sass/sizing.scss";
@import "../../assets/sass/colors.scss";

.header-container h5 {
  font-family: "ducati-extended-bold", Arial;
  font-size: 16px !important;
}

.header-container {
  background-color: $white;
  // top: 0;
  // position: sticky;
  // z-index: 1;
  font-family: "ducati-extended", Arial;
}

.back-btn--scrambler {
  background-color: $white;
}

.back-btn--scrambler h5 {
  font-family: "scrambler-bold";
  font-size: 16px;
}

.font {
  margin-left: 36px;
}

.border_menu {
  border-right: 1px solid #eaeaea;
}

.margin_return {
  margin-left: 32px;
}

.float {
  float: right;
}

.margin-back {
  margin-bottom: 3px;
}
.header_sticky {
  z-index: 9;
}
.header-logo {
  cursor: pointer;
}

@media (max-width: $width-medium-max) {
  .headerPosition {
    position: fixed;
    top: 0;
    z-index: 1;
    height: 76px;
  }
}
