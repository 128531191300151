@import "../../assets/sass/colors.scss";
@import "../../assets/sass/sizing.scss";

.footer {
  background: $smoky-black;
  text-align: center;
  padding: 17px 20px;
}

.font-descr {
  font-family: "ducati" !important;
  font-size: 12px;
  line-height: 16px;
  color: $feature-grey;
  letter-spacing: 0.008em;
  display: inline !important;
}

.font-title {
  color: $feature-grey !important;
  font-family: "ducati-condensed-bold-extra" !important;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.links-container {
  text-transform: uppercase;
}

@media (max-width: $width-medium-max) {
  .footer-img {
    width: 360px !important;
  }
}

@media (min-width: $width-large-min) {
  .footer-img {
    width: 480px !important;
  }

  .links-container {
    padding-top: 10px;
  }
  .font-title {
    font-size: 16px;
  }
  .font-descr {
    font-size: 16px;
    line-height: 20px;
  }
}
