@import "../../../../common/assets/sass/colors.scss";

.edit-price-form-container {
  display: grid;
}

.borders {
  border-top: 1px solid $feature-grey;
  border-bottom: 1px solid $feature-grey;
}
