@import "../../../../assets/sass/sizing.scss";
@import "../../../../assets/sass/button.scss";

.header-dropdown h6,
.header-offcanvans h6,
.header-offcanvans-logged h6 {
  font-family: "ducati-condensed-bold";
}

.header-dropdown h6 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
}

.header-dropdown a {
  border: 0 !important;
}

.header-dropdown-button,
.header-dropdown-button-logged {
  text-transform: uppercase !important;
  border-radius: 2px !important;
}

.header-dropdown-button {
  border: 1px solid $dark-gray !important;
}

.header-dropdown-button-logged {
  border: 1px solid $ducati-red !important;
  background-color: $ducati-red !important;
}

.header-dropdown-button-logged h6 {
  color: $white !important;
}

.header-dropdown-button-up {
  transform: scaleY(-1);
}

.header-dropdown-content,
.header-dropdown-content-logged {
  border: 1px solid $light-gray !important;
  border-radius: 2px !important;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.05);
}

.header-dropdown-content-logged button {
  border: 0 !important;
  text-transform: uppercase !important;
}

.header-dropdown-content,
.header-dropdown-content li {
  min-width: 132.5px !important;
}

.header-dropdown-content li a,
.header-dropdown-content li button,
.header-dropdown-content-logged li,
.header-dropdown-content-logged li a,
.header-dropdown-content-logged li button {
  font-family: "ducati-condensed-bold" !important;
  font-size: 14px !important;
  line-height: 26px !important;
  color: $dark-gray !important;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
}

.header-dropdown-content li button {
  text-transform: uppercase;
  padding: 0;
}

.header-dropdown-content li,
.header-dropdown-content-logged li {
  width: auto !important;
  padding-top: 7px !important;
  padding-left: 15px !important;
  padding-right: 0 !important;
  padding-bottom: 7px !important;
  border-bottom: 1px solid $light-gray !important;
  list-style-type: none;
}

.header-dropdown-content-logged li {
  padding-right: 32px !important;
}

.header-dropdown-content li:active,
.header-dropdown-content li:hover,
.header-dropdown-content li:focus,
.header-dropdown-content li:visited,
.header-dropdown-content-logged li:active,
.header-dropdown-content-logged li:hover,
.header-dropdown-content-logged li:focus,
.header-dropdown-content-logged li:visited {
  background: none !important;
  color: $dark-gray !important;
}

.header-dropdown-content li:last-child,
.header-dropdown-content-logged li:last-child,
.header-offcanvans-logged-list li:last-child {
  border-bottom: none !important;
}

.header-offcanvans {
  border-top: 6px solid $light-gray;
}

.header-offcanvans h6 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 00.007em;
  text-transform: uppercase;
  margin-top: 30px !important;
  margin-left: 28px !important;
  margin-bottom: 24px !important;
  padding: 0;
}

.btn-login,
.btn-register {
  padding-left: 28px;
  padding-right: 26px;
}

.btn-login button,
.btn-register a {
  font-family: "ducati-extended-bold";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 4px;
}

.btn-login button {
  background: $ducati-red;
  color: $off-white;
  width: 100%;
}

.btn-register a {
  background: $off-white;
  color: $smoky-black;
  border: 1px solid $smoky-black !important;
  margin-top: 7px;
}

.btn-login button:active,
.btn-login button:hover,
.btn-login button:focus,
.btn-login button:visited {
  color: $off-white !important;
}

.btn-register a:active,
.btn-register a:hover,
.btn-register a:focus,
.btn-register a:visited {
  color: $smoky-black !important;
}

.header-offcanvans-logged-list ul {
  font-family: "ducati-extended-bold", Arial !important;
  color: $smoky-black !important;
}

.header-offcanvans-logged-list li {
  font-family: "ducati-extended-bold" !important;
  font-size: 18px;
  line-height: 28px;
  list-style-type: none;
  padding-left: 28px;
  padding-top: 33px;
  padding-bottom: 37px;
  border-bottom: 1px solid $light-gray !important;
}

.header-offcanvans-logged-list li a,
.header-offcanvans-logged-list li button {
  color: $smoky-black !important;
}

.header-offcanvans-logged-list li button {
  padding: 0;
  font-size: 18px;
  line-height: 28px;
  border: none;
  background-color: transparent;
}

.profile-image {
  width: 36.67px !important;
  height: 36.67px !important;
  border: 2px solid $ducati-red;
  border-radius: 50%;
}

.font2 {
  font-family: "ducati-condensed-bold";
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.cursor {
  cursor: pointer;
}

.border_hover:hover {
  border: 1px solid black;
  border-radius: 20px;
}

.margin_top3 {
  margin-top: 24px;
}

@media (max-width: $width-medium-max) {
  .font2 {
    font-family: "ducati-condensed";
  }
}
