@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";

.apparels-gallery-list-container {
  max-height: 80vh;
}

.apparel-list-container {
  padding-top: 52px !important;
}

.apparels-gallery-list-content {
  flex-wrap: wrap !important;
  flex-direction: row !important;
  justify-content: center;
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.apparel-item {
  margin-right: 11px !important;
  margin-bottom: 8px !important;
}

@media (min-width: $width-large-min) {
  .apparel-list-container {
    padding: 0px !important;
  }
  .apparel-item {
    width: 30% !important;
    margin-right: 21px !important;
    margin-bottom: 68px !important;
  }
  .apparels-gallery-list-content {
    justify-content: start;
  }
}
