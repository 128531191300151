@import "../../../assets/sass/button.scss";

.save-myducati-modal-container {
  padding-left: 83px !important;
  padding-right: 83px !important;
  padding-bottom: 86px !important;
}

.save-myducati-modal-title {
  font-family: "ducati-extended-bold", Arial;
  letter-spacing: 0.007em;
  font-size: 18px;
  line-height: 28px;
  color: $black;
}

.save-myducati-modal-btn-container {
  display: flex;
  justify-content: center;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0 !important;
}

.btn-dimension {
  width: 478px;
  height: 56px;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
