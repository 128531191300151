@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.test-ride-accordion-container {
  border-top: 1px solid $feature-grey;
  border-bottom: 1px solid $feature-grey;
  margin-left: 20px;
  margin-right: 20px;
}

.test-ride-form-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 46px;
}

.test-ride-title-label {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: $black;
}

.test-ride-radio-btn-label {
  font-family: "ducati";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $black;
  margin-left: 21px;
}

.test-ride-form-content input[type="checkbox"] {
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 2px solid $smoky-black !important;
  border-radius: 2px !important;
}

.test-ride-form-content input[type="text"] {
  width: 143px;
  height: 62px;
  text-align: center;
  margin-left: 10px;
  font-family: "ducati";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: $feature-grey;
  border: 1px solid $smoky-black !important;
  border-radius: 4px !important;
}

.test-ride-form-content input[type="checkbox"]:checked {
  background-image: url("../../../common/assets/images/check-icon-black.svg");
}

.test-ride-form-content input[type="checkbox"],
.test-ride-form-content input[type="text"] {
  background-color: transparent;
}

.test-ride-form-validity {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.test-ride-form-content {
  padding-bottom: 39px;
  border-bottom: none !important;
}

.test-ride-form-validity {
  margin-top: 39px !important;
}

.test-ride-accordion-button {
  outline: none !important;
  box-shadow: none;
  font-family: "ducati-bold";
}

.test-ride-accordion-body {
  padding-left: 19px !important;
  padding-right: 19px !important;
  padding-bottom: 0 !important;
}

@media screen and (min-width: $width-large-min) {
  .test-ride-form-validity {
    margin-top: 0 !important;
  }
  .test-ride-form-validity {
    justify-content: flex-start;
  }
}
