@import "../../../common/assets/sass/colors.scss";
@import "../../../common/assets/sass/sizing.scss";

.container-mtsmhp {
  display: flex;
}

.container-mtsmhp-b2b {
  display: flex;
  min-height: 100vh;
}

.right-side-container {
  background-color: $off-white;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-side-container-MTSMHP {
  background-color: rgba($color: white, $alpha: 0.7);
  display: flex;
  flex-direction: column;
  min-height: 90%;
  justify-content: space-between;
  border-radius: 10px;
}

.sfondo {
  background-color: rgba($color: white, $alpha: 0.7);
  width: 100% !important;
}

.contentStatusCarrello {
  overflow: hidden;
}
.contentStatusCarrello::-webkit-scrollbar {
  width: 3px;
}

/* Stile del cursore della scrollbar */
.contentStatusCarrello::-webkit-scrollbar-thumb {
  background-color: $ducati-red;
  border-radius: 4px;
}

.content {
  background-color: $bg-color-2;
}

.right-side {
  height: 100%;
}

.recap-container {
  position: absolute;
  bottom: 0;
}

.arrow {
  background-color: $bg-color-2;
  width: 32px !important;
  display: flex;
  justify-content: center;
  border: 1px solid $black !important;
  border-right: 0 !important;
  height: 65px;
  cursor: pointer;
  transition: transform 0.5s ease-in;
}

.arrowb2c {
  top: 20px;
  z-index: 1;
}

.arrowb2b {
  z-index: 1;
}

button.a arrowRight:hover {
  transform: translateX(6px) !important;
}

button.arrowLeft:hover {
  transform: translateX(-6px) !important;
}

button.arrowRight,
button.arrowLeft {
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    margin-top: 20px;
  }
}
.arrowHidden {
  height: 90vh;
  transform: scaleY(1);
  border: none !important;
}

.top1 {
  margin-top: 0 !important;
}

.right_side {
  transition: transform 0.5s ease-in;
}

@media (min-width: $width-720p-max) {
  .sfondo {
    width: 440px !important;
  }
  .shift-right-img {
    transform: translateX(440px);
  }
}

@media (min-width: $width-large-min) and (max-width: $width-720p) {
  .sfondo {
    width: 360px !important;
  }
  .shift-right-img {
    transform: translateX(360px);
  }
}

@media (min-width: $width-large-min) {
  .sfondo {
    background-color: rgba($color: white, $alpha: 0.75) !important;
    border-radius: 10px 0 0 0;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
  .padding-right {
    padding-right: 10px;
  }

  .calc_height {
    height: calc(90vh - 20px);
  }
  .shift-left-img {
    transform: translateX(0);
  }

  .container-mtsmhp {
    height: calc(100vh - 76px - 9px - 10px);
    margin-top: 9px;
    margin-bottom: 10px;
  }
  .container-mtsmhp-b2b {
    height: calc(100vh - 76px);
    margin-top: 10px;
  }

  .container-mts {
    transition: width 0.5s linear;
    width: 440px;
  }

  .shift-right {
    width: 32px; // Size of the button
  }

  .right_side {
    max-height: calc(100vh - 76px - 9px - 10px);
  }
}
