@import "../../assets/sass/colors.scss";

.b2b-bike-details-container {
  margin-bottom: 50px;
}

.b2c-bike-details-container {
  margin-bottom: 1rem;
}

.b2b-bike-details-title {
  font-family: "ducati-extended-bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.007em;
  color: $black;
  padding: 0 !important;
}

.b2b-simple-label {
  color: $dark-gray;
}
.b2b-simple-label-black {
  color: $smoky-black;
}

.b2b-simple-label,
.b2b-simple-label-black {
  font-family: "ducati";
}

.b2c-simple-label {
  font-family: var(--font-regular);
}

.b2b-simple-label,
.b2b-simple-label-black {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  margin: 0;
  padding: 0;
}

.b2c-simple-label {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.007em;
  color: $black;
  padding: 0 !important;
  margin: 0 !important;
}

.b2b-bike-details-item {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 10px !important;
  padding-right: 0 !important;
  border-bottom: 1px solid $feature-grey;
}

.b2c-bike-details-item {
  padding: 0 !important;
  margin-bottom: 1rem !important;
}

.float {
  float: right;
}

@media (min-width: 992px) {
  .b2b-simple-label {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.007em;
  }
}
