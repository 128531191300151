@import "../../../../../../assets/sass/colors.scss";
@import "../../../../../../assets/sass/sizing.scss";

.add2cart-container {
  border-top: 1px solid $smoky-black;
  background: $white;
  width: 100% !important;
  position: sticky;
  bottom: 0vh;
  z-index: 2;
  right: 0;
}

.add2cart-content {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start;
  width: 100%;
}

.resize-image {
  border: 1px solid $feature-grey !important;
  border-radius: 0 !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.normal-label {
  font-family: "ducati";
}
.bolded-label {
  font-family: "ducati-bold";
}

.normal-label,
.bolded-label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: $smoky-black;
  width: auto !important;
  margin: 0 !important;
}

@media (max-width: $width-medium-max) {
  .add2cart-container {
    z-index: -1;
  }

  .add2cart_translation {
    animation: translateAnimation 2s infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
  }

  @keyframes translateAnimation {
    0% {
      transform: translateY(100px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(100px);
    }
  }
}
